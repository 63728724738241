import { createPortal } from 'react-dom';
import { currentModalHandler, CurrentModal } from './current-modal-handler';
import { Suspense, useEffect, useState } from 'react';

export const ModalRenderer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modal, updateCurrentModal] = useState<CurrentModal<any> | null>(null);

  useEffect(() => currentModalHandler.subscribe(updateCurrentModal), []);

  if (modal) {
    const Modal = currentModalHandler.get(modal.name);

    return <Suspense>{createPortal(<Modal {...modal?.props} />, document.body)}</Suspense>;
  }

  return null;
};
