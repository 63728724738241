import { Modals } from './modals-config';
import { useModal } from './use-modal';

type ModalProps = {
  closeModal: () => void;
  email?: string;
  token?: string;
  uidb64?: string;
  notConfirmEmail?: boolean;
};

type useModalHandlerProps = {
  notificationType: keyof typeof Modals;
  email?: string;
  token?: string;
  uidb64?: string;
  notConfirmEmail?: boolean;
};

export const useModalHandler = ({
  notificationType,
  email,
  token,
  uidb64,
  notConfirmEmail,
}: useModalHandlerProps) => {
  const modal = useModal<ModalProps>(Modals[notificationType]);

  return () => {
    modal.open({
      closeModal: modal.close,
      email: email,
      token: token,
      uidb64: uidb64,
      notConfirmEmail: notConfirmEmail,
    });
  };
};
