import { baseTheme } from 'src/styles';
import styled, { keyframes } from 'styled-components/macro';
import { SectionTitle } from '../components/style';

export const Container = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
  padding: 0 108px;
  @media ${baseTheme.media.desktopL} {
    padding: 0 60px;
  }
  @media ${baseTheme.media.laptopXL} {
    gap: 70px;
    ${SectionTitle} {
      line-height: 150%;
    }
  }
  @media ${baseTheme.media.laptopL} {
    padding: 0 76px;
    gap: 60px;
  }
  @media ${baseTheme.media.tablet} {
    padding: 0 24px;
    gap: 40px;
    ${SectionTitle} {
      line-height: 100%;
    }
  }
  @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
    gap: 32px;
  }
  @media ${baseTheme.media.mobileS} {
    gap: 34px;
  }
`;

const text = keyframes`
  0% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 121px;
  display: flex;
  align-items: center;
  @media ${baseTheme.media.laptopL} {
    height: 60px;
  }
  @media ${baseTheme.media.tablet} {
    height: 42px;
  }
  @media ${baseTheme.media.mobileM} {
    height: 27px;
  }
`;

export const PartnersList = styled.ul`
  position: absolute;
  /* top: 0; */
  right: 0;
  display: flex;
  justify-content: space-around;
  /* width: 100%;
  max-width: 1770px; */
  width: 2370px;
  /* gap: 120px; */
  /* height: 100%; */
  animation: ${text} 30s infinite linear forwards;
  transform: translate(100%, 0);
  overflow: hidden;
  & li {
    align-self: center;
  }
  @media ${baseTheme.media.desktopL} {
    width: 1992px;
    & li a img {
      width: 80%;
      height: 80%;
    }
  }
  @media (max-width: 1366px) {
    width: 1840px;
    & li a img {
      width: 73%;
    }
  }
  @media ${baseTheme.media.laptopL} {
    width: 1304px;
    & li a img {
      width: auto;
      max-width: 292px;
      height: auto;
    }
  }
  @media ${baseTheme.media.tablet} {
    width: 1032px;
    & li a img {
      max-width: 216px;
    }
  }
  @media ${baseTheme.media.mobileM} {
    width: 688px;
    & li a img {
      max-width: 140px;
    }
  }
`;

export const SecondPartnersList = styled(PartnersList)`
  animation: 30s ${text} 15s infinite linear forwards;
`;

// export const Partner = styled.li`
//   /* display: block; */
//   /* width: 100%;
//   height: 96px; */
// `;

// export const PartnerLink = styled.a`
//   /* display: block; */
//   /* width: 465px;
//   height: 96px; */
//   /* background-image: url('/images/icons/alfa-black.png'); */
// `;

// export const Img = styled.img`
//   display: block;
//   /* width: 465px;
//   height: 96px;
//   background-image: url('/images/icons/alfa-black.png'); */
// `;
