import { whyExlabSectionData } from '..';
import { ButtonAsLink, ButtonPrimary } from '../../buttons';
import { useModalHandler } from '../../modal';
import { SectionSubTitle } from '../components/style';
import {
  MainButton,
  Icon,
  PrimePhoto,
  TextBlock,
  Title,
  Wrapper,
  PhotoContainer,
  MobileTitle,
  SubTitle,
  PartTitle,
} from './style';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const MainScreen = ({ primary, light }: ForJuniorSectionProps) => {
  const getRegistrationModal = useModalHandler({ notificationType: 'RegistrationModal' });
  const successfulRegistrationModal = useModalHandler({
    notificationType: 'NoticeSuccessfulRegistration',
  });
  return (
    <Wrapper>
      <TextBlock>
        <SubTitle>Стартап твоей карьеры в IT</SubTitle>
        <Title>
          <PartTitle>Получи</PartTitle>
          <PartTitle>
            Тот самый опыт <Icon />
          </PartTitle>
        </Title>
        <MainButton type='button' onClick={getRegistrationModal}>
          Присоединиться
        </MainButton>
      </TextBlock>
      <PhotoContainer>
        <picture>
          <source
            srcSet='/images/main-page/main-section/main-800.jpg 1x, /images/main-page/main-section/main-800-2x.jpg 2x'
            media='(max-width: 800px)'
          />
          <source
            srcSet='/images/main-page/main-section/main-1280.jpg 1x, /images/main-page/main-section/main-1280-2x.jpg 2x,'
            media='(max-width: 1280px)'
          />
          <source
            srcSet='/images/main-page/main-section/main-1366.jpg 1x, /images/main-page/main-section/main-1366-2x.jpg 2x'
            media='(max-width: 1366px)'
          />
          <source
            srcSet='/images/main-page/main-section/main-1536.jpg 1x, /images/main-page/main-section/main-1536-2x.jpg 2x'
            media='(max-width: 1536px)'
          />
          <source
            srcSet='/images/main-page/main-section/main-photo.jpg 1x, /images/main-page/main-section/main-photo-2x.jpg 2x'
            media='(max-width: 1920px)'
          />

          <PrimePhoto alt='main photo' src='/images/main-page/main-section/main-photo.jpg' />
        </picture>

        {/* <img
          srcSet='small-car-image.jpg 400w,
             medium-car-image.jpg 800w,
             large-car-image.jpg 1200w'
          sizes='(min-width: 1280px) 1200px,
            (min-width: 768px) 400px,
            100vw'
          src='medium-car-image.jpg'
          alt='Car'
        /> */}
      </PhotoContainer>
      <MobileTitle>
        Получи <Icon />
        <br /> Тот самый опыт
      </MobileTitle>
    </Wrapper>
  );
};
