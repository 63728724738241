import { alfaAPI } from 'src/services/rates-service';
import { ButtonPrimary } from '../../buttons';
import { LightTitle, PrimaryTitle } from '../components/style';
import { description, paymentServices } from './data';
import {
  Container,
  LeftColumn,
  Wrapper,
  RemarkInfo,
  PaymentServices,
  PaymentService,
  RightColumn,
  DescriptionTitle,
  Description,
  DescriptionСonditions,
  TotalField,
  Sum,
  DescriptionTitleMobile,
} from './style';
import { useEffect, useState } from 'react';
import { Loader } from '../../loader';
import { useModalHandler } from '../../modal';
import { RadioInput } from '../../inputs/radio/radio-input';

const tariffs = [
  {
    name: 'tariff',
    id: 'tariff_1',
    value: 150,
    text: '1 месяц',
    summ: '150 USD*',
    withExtra: false,
  },
  {
    name: 'tariff',
    id: 'tariff_2',
    value: 360,
    text: '3 месяца',
    summ: '360 USD*',
    withExtra: true,
  },
];

export const SubscriptionSection = () => {
  const getUnknownErrModal = useModalHandler({ notificationType: 'NoticeUnknownErr' });

  const [selectedTariff, setTariff] = useState<number>(150);
  const [rate, setRate] = useState<number>(0);

  const handleTafiffs = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTariff(+e.currentTarget.value);
  };

  const getSummInBYN = () => {
    if (selectedTariff) {
      const summ = selectedTariff * rate;
      return +summ.toFixed(1);
    }
    return 0;
  };

  const { data, isSuccess, isError, isLoading } = alfaAPI.useGetRatesQuery(undefined, {
    skip: Boolean(!selectedTariff),
  });

  useEffect(() => {
    if (isSuccess) {
      // console.log('get currency', data); //проверить длину массива и есть ли rate или выкинуть error
      if (data.rates[0] && data.rates[0].rate) {
        const currentRate = data.rates[0].rate; //получить курс валюты
        // console.log('currentRate', currentRate);
        setRate(currentRate);
      } else {
        getUnknownErrModal();
        setTariff(40);
        // console.log('error api'); // notification smt go wrong
      }
    }
    if (isError) {
      // console.log('err get currency');
    }
  }, [data, getUnknownErrModal, isError, isSuccess]);

  return (
    <Container>
      <LightTitle>
        Оформить
        <PrimaryTitle as='span'> подписку</PrimaryTitle>
      </LightTitle>
      <Wrapper>
        <LeftColumn>
          <Description>
            <DescriptionTitle>
              Оформи подписку сейчас <span> и получи возможность:</span>
            </DescriptionTitle>
            <DescriptionTitleMobile>
              Оформи подписку сейчас и получи возможность:
            </DescriptionTitleMobile>
            <DescriptionСonditions as='ul'>
              {description.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </DescriptionСonditions>
          </Description>
        </LeftColumn>
        <RightColumn>
          {tariffs.map(({ name, id, value, text, summ, withExtra }) => (
            <RadioInput
              key={id}
              name={name}
              id={id}
              htmlFor={id}
              value={value}
              checked={value === selectedTariff}
              text={text}
              summ={summ}
              withExtra={withExtra}
              onChange={handleTafiffs}
            />
          ))}
          <RemarkInfo>*Оплата по курсу НБ РБ на дату совершения платежа</RemarkInfo>
          <TotalField>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Sum>Всего к оплате</Sum>
                <Sum>{selectedTariff ? `${getSummInBYN()} BYN` : '0 BYN'}</Sum>
              </>
            )}
          </TotalField>
          <ButtonPrimary
            type='submit'
            id='subscription'
            form='tariffs'
            text='Оформить подписку'
            weight
          />
          <PaymentServices>
            {paymentServices.map(({ id, width, height }) => (
              <PaymentService
                key={id}
                width={width}
                height={height}
                img={`/images/icons/payment-services/service${id}.svg`}
              ></PaymentService>
            ))}
          </PaymentServices>
        </RightColumn>
      </Wrapper>
    </Container>
  );
};
