import { baseTheme } from 'src/styles';
import styled from 'styled-components';

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;

  /* @media ${baseTheme.media.tablet} {
    width: 77px;
    height: 32px;
  } */
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: #cdd3d7;
  background-color: transparent;
  outline: 0;
  border: 0;

  /* @media ${baseTheme.media.tablet} {
    width: 77px;
    height: 32px;
  } */
`;

export const Icon = styled.span<{ isOpen: boolean }>`
  display: block;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  background-image: url('/images/icons/arrow-down.svg');
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  transition: transform 0.3s ease-in-out;
  /* @media ${baseTheme.media.tablet} {
    width: 77px;
    height: 32px;
  } */
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* @media ${baseTheme.media.tablet} {
    width: 77px;
    height: 32px;
  } */
`;
