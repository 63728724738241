import { useOutsideClick } from 'src/utils/use-outside-click';
import { BurgerButton } from './styles';
import { useRef } from 'react';

type BurgerMenuProps = {
  isOpen: boolean;
  toggleBurgerMenu?: () => void;
  closeMenu: () => void;
};

export const BurgerMenu = ({ isOpen, toggleBurgerMenu, closeMenu }: BurgerMenuProps) => {
  const ref = useOutsideClick(closeMenu);
  return (
    <BurgerButton type='button' onClick={toggleBurgerMenu} isOpen={isOpen} ref={ref}>
      <span />
      <span />
      <span />
    </BurgerButton>
  );
};
