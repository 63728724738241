import { Main, Text, TextColored } from './styles';

export const DevelopmentPage = () => {
  return (
    <Main>
      <Text>
        Страница находится <TextColored as='span'>в разработке</TextColored>
      </Text>
    </Main>
  );
};
