export const linksData = [
  {
    id: '1',
    href: '/#projects',
    title: 'Проекты',
  },
  {
    id: '2',
    to: 'members',
    title: 'Участники',
  },
  // {
  //   id: '3',
  //   to: 'mentors',
  //   title: 'Менторы',
  // },
  {
    id: '4',
    href: '/#about',
    title: 'О нас',
  },
  {
    id: '5',
    href: 'https://boosty.to/exlab_startup#donat',
    title: 'Донат',
  },
];
