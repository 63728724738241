import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';
import {
  PrimaryTitle,
  SectionElementDescription,
  SectionSubTitle,
  SectionTitle,
} from '../components/style';

export const Wrapper = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
  padding: 0 108px;
  @media (max-width: 1890px) {
    padding: 0 60px;
  }
  @media ${baseTheme.media.laptopXL} {
    padding: 0 41px;
    ${SectionTitle}, ${PrimaryTitle} {
      line-height: 120%;
    }
  }
  @media ${baseTheme.media.laptopL} {
    /* max-width: 905px; */
    gap: 40px;
    padding: 0 76px;
  }
  @media ${baseTheme.media.tablet} {
    gap: 48px;
    padding: 0 24px;
  }
  @media ${baseTheme.media.mobileL} {
    min-width: 100%;
    ${SectionTitle}, ${PrimaryTitle} {
      line-height: 100%;
    }
    gap: 24px;
  }
  @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
  }
`;
export const WrapperStages = styled.div`
  width: 100%;
  /* @media (max-width: 1890px) {
    padding: 0 60px;
  }
  @media (max-width: 1366px) {
    padding: 0 41px;
  }
  @media ${baseTheme.media.laptopL} {
    gap: 40px;
    padding: 0 76px;
  }
  @media ${baseTheme.media.tablet} {
    gap: 48px;
    padding: 0 24px;
  } */
  /* @media ${baseTheme.media.mobileL} {
    min-width: 100%;
  } */
  /* @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
  } */
`;

export const StagesContainer = styled.ul`
  /* width: 100%; */
  /* height: max-content; */
  /* max-height: 300px; */

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* display: flex; */
  /* justify-content: space-between; */
  column-gap: 24px;
  @media (max-width: 1280px) {
    /* width: auto; */
    column-gap: 16px;
    overflow: auto;
    overflow-y: hidden;
    padding-left: 76px;
    padding-right: 76px;
    margin-left: -76px;
    margin-right: -76px;
  }
  @media ${baseTheme.media.tablet} {
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
  }
  @media ${baseTheme.media.mobileL} {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0;
    margin: 0;
    overflow: unset;
  }
  .swiper {
    max-width: 100%;
  }
  .swiper-slide {
    max-width: 232px;
    /* margin-right: 16px; */
    border-right: 2px solid #031926;
    &:last-child {
      border-right: none;
    }
  }
`;

export const StagesTitle = styled(SectionSubTitle)`
  @media ${baseTheme.media.tablet} {
    padding-bottom: 16px;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const Stage = styled.li`
  max-width: 408px;
  min-height: 408px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex-basis: 25%;
  padding-right: 16px;
  border-right: 2px solid #031926;
  &:last-child {
    padding-right: 0px;
    border: none;
  }
  @media (max-width: 1366px) {
    min-height: 336px;
    min-width: 232px;
  }
  @media ${baseTheme.media.laptopL} {
    min-height: 288px;
  }
  @media ${baseTheme.media.tablet} {
    min-height: 201px;
    padding-top: 5px;
    padding-right: 14px;
    &:first-child {
      ${SectionElementDescription} {
        max-width: 190px;
      }
    }
    &:nth-child(2n) {
      ${SectionElementDescription} {
        max-width: 177px;
      }
    }
  }
  @media ${baseTheme.media.mobileL} {
    max-width: 100%;
    min-height: auto;
    gap: 10px;
    flex-direction: row;
    flex-basis: 100%;
    padding-top: 0px;
    padding-right: 0;
    border-right: none;
    &:first-child,
    &:nth-child(2n) {
      ${SectionElementDescription} {
        max-width: 100%;
        line-height: 143%;
      }
    }

    &:last-child p {
      line-height: 24px;
    }
  }
`;

export const StageNumber = styled.div`
  //padding-top: 128px;
  margin-top: auto;
  color: #475359;
  font-family: Noto Sans;
  font-size: 144px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  @media ${baseTheme.media.desktopL} {
    font-size: 120px;
  }
  @media (max-width: 1366px) {
    font-size: 96px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 72px;
  }
  @media ${baseTheme.media.tablet} {
    font-size: 58px;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;
export const StageNumberMobile = styled.div`
  display: none;
  @media ${baseTheme.media.mobileL} {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 8px 11px;
    order: 1;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    background-color: #475359;
    border-radius: 50%;
  }
`;
