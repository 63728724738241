import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegistrationFormValues } from 'src/features/users/registration/registration-form';
import { Cookies } from 'react-cookie';

interface UserReducerState {
  // user: User | null;
  token: string | null;
  refreshToken: string | null;
  isAuth: boolean;
  data: RegistrationFormValues | null;
}

const cookies = new Cookies();

export const initialState: UserReducerState = {
  // user: null,
  token: cookies.get('userAccessToken'),
  refreshToken: cookies.get('userRefreshToken'),
  isAuth: Boolean(cookies.get('userAccessToken')),
  data: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{ token: string; refreshToken: string; isAuth: boolean }>
    ) {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.isAuth = action.payload.isAuth;
    },
    setRegistrationData(state, action: PayloadAction<RegistrationFormValues | null>) {
      state.data = action.payload;
    },
    logoutUser(state) {
      state.token = null;
      state.isAuth = false;
      cookies.remove('userAccessToken');
    },
  },
});

export const { setUser, setRegistrationData, logoutUser } = userSlice.actions;
export const userReduser = userSlice.reducer;
