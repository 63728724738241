import { EmailsList } from './emails-list';
import { BankInfo } from './bank-details';

export const linksData = [
  {
    id: '1',
    to: '/merch',
    title: 'Мерч',
  },
  {
    id: '2',
    href: 'https://youtube.com/@ExLab?feature=share8',
    title: 'Youtube',
    titleShort: 'ytb',
  },
  {
    id: '3',
    href: 'https://boosty.to/exlab_startup',
    title: 'Boosty',
  },
  {
    id: '4',
    href: 'https://t.me/ExLabChannel',
    title: 'Telegram',
    titleShort: 'tgrm',
  },
  {
    id: '5',
    to: 'eng-crab',
    title: 'English Crab',
  },
  {
    id: '6',
    href: 'https://www.linkedin.com/company/exlab-start-up',
    title: 'LinkedIn',
    titleShort: 'lnkdn',
  },
  {
    id: '7',
    href: 'https://drive.google.com/file/d/1l-s-1xfv-jsLo5hKkj1vkIxEbLbHkYF5/view',
    title: 'Оплата',
  },
  {
    id: '8',
    href: 'https://www.instagram.com/exlab_startup',
    title: 'Instagram',
    titleShort: 'instgrm',
  },
  {
    id: '9',
    href: '/user-agreement.pdf',
    title: 'Пользовательское соглашение',
  },
];
export const footerData = [
  {
    id: '1',
    title: 'Полезные ссылки',
    content: linksData.filter((_, index) => index % 2 === 0),
  },
  {
    id: '2',
    title: 'Оплата и Банковские реквизиты',
    content: BankInfo,
  },
  {
    id: '3',
    title: 'Социальные сети',
    content: linksData.filter((_, index) => index % 2 !== 0),
  },
  {
    id: '4',
    title: 'Контакты',
    content: EmailsList,
  },
];
