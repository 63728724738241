import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const Main = styled.main<{ $auth?: boolean }>`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  gap: 140px;
  @media ${baseTheme.media.desktopL} {
    max-width: 1536px;
    margin-bottom: 120px;
    gap: 120px;
  }
  @media ${baseTheme.media.laptopXL} {
    max-width: 1366px;
    margin-top: 20px;
    /* margin-bottom: 222px; */
    margin-bottom: 90px;
    gap: 90px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 1280px;
    margin-top: 24px;
    margin-bottom: 80px;
    gap: 80px;
  }
  @media ${baseTheme.media.laptop} {
    max-width: 1024px;
    margin-bottom: 60px;
    gap: 60px;
  }
  @media ${baseTheme.media.mobileL} {
    margin-top: 16px;
    gap: 42px;
  }
`;
