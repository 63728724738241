import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const Wrapper = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 168px; */
  padding: 0 108px;
  @media ${baseTheme.media.desktopL} {
    padding: 0 60px;
  }
  @media (max-width: 1366px) {
    /* gap: 96px; */
    padding: 0 41px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 0 76px;
    /* gap: 40px; */
  }
  @media ${baseTheme.media.tablet} {
    padding: 0 24px;
    /* gap: 48px; */
  }
  @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
    /* gap: 32px; */
  }
`;

export const AchievementsContainer = styled.ul`
  width: 100%;
  /* display: flex;
  justify-content: space-between; */
  /* gap: 24px; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 264px;
  gap: 24px;
  margin-top: 100px;
  @media ${baseTheme.media.desktopL} {
    margin-top: 80px;
  }
  @media (max-width: 1366px) {
    margin-top: 60px;
  }
  @media ${baseTheme.media.laptopL} {
    margin-top: 40px;
    grid-auto-rows: 200px;
  }
  @media ${baseTheme.media.tablet} {
    /* gap: 72px; */
    grid-auto-rows: 112px;
    margin-top: 48px;
  }
  @media ${baseTheme.media.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }
  @media ${baseTheme.media.mobileM} {
    gap: 48px;
    margin-top: 24px;
  }
`;

export const Achievement = styled.li`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 24px; */
  flex: 0 0 24%;
  padding: 24px 10px;
  text-align: center;
  @media ${baseTheme.media.tablet} {
    padding: 0;
  }
`;

export const Amount = styled.div<{ weight?: string }>`
  margin-bottom: 24px;
  color: #29292b;
  text-align: center;
  font-family: Noto Sans;
  font-size: 144px;
  font-style: normal;
  font-weight: 300;
  line-height: 90%;
  @media ${baseTheme.media.desktopL} {
    font-size: 120px;
  }
  @media (max-width: 1366px) {
    font-size: 96px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 72px;
  }
  @media ${baseTheme.media.tablet} {
    font-size: 58px;
  }
  @media ${baseTheme.media.mobileM} {
    margin-bottom: 12px;
    font-size: 34px;
  }
`;
