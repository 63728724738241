import { Link } from 'react-router-dom';
import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';
import { LinkLogo } from '../logo/styles';

export const Wrapper = styled.footer`
  padding: 72px 108px;
  display: flex;
  justify-content: space-between;
  background-color: #1a2226;
  /* @media ${baseTheme.media.desktopL} {
    padding: 72px 60px;
  } */
  @media (max-width: 1366px) {
    padding: 48px 60px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 32px 76px;
  }
  @media (max-width: 1200px) {
    gap: 65px;
  }
  @media ${baseTheme.media.tablet} {
    padding: 32px 30px; //padding left-right не по макету, так лучше для адаптива
    gap: 0;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    /* gap: 16px; */
  }
  @media ${baseTheme.media.mobileL} {
    padding: 32px 16px;
  }
`;

export const Container = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1620px) {
    /* max-width: 130px; */
    max-width: 96px;
  }
  @media ${baseTheme.media.desktopL} {
    padding: 0;
  }
  @media ${baseTheme.media.laptopL} {
    justify-content: flex-start;
    max-width: 122px;
  }
  @media (max-width: 1200px) {
    /* gap: 40px; */
  }
  @media ${baseTheme.media.tablet} {
    max-width: 96px;
    ${LinkLogo} > svg {
      width: 96px;
      height: 47px;
    }
  }
  @media (max-width: 700px) {
    max-width: 100%;
    order: 2;
  }
`;

export const Copyright = styled.p`
  color: #d9d9d9;
  font-size: 16px;
  line-height: 130%;
  @media ${baseTheme.media.desktopL} {
    display: flex;
    flex-direction: column;
    max-width: 97px;
  }
  @media (max-width: 1300px) {
    max-width: 122px;
    display: block;
  }
  @media ${baseTheme.media.laptopL} {
    margin-top: 32px;
  }
  @media (max-width: 1200px) {
    margin-top: 40px;
    font-size: 14px;
    max-width: 97px;
  }
  @media ${baseTheme.media.tablet} {
    margin-top: 57px;
  }
  @media (max-width: 700px) {
    max-width: 100%;
    margin-top: 16px;
    color: rgba(205, 211, 215, 0.6);
    font-size: 12px;
  }
`;

export const LinksWrapper = styled.nav`
  width: 100%;
  max-width: 517px;
  padding-left: 15px;
  /* padding-right: 58px;
  padding-left: 58px; */
  @media ${baseTheme.media.desktopL} {
    padding: 0;
    max-width: 437px;
  }
  @media ${baseTheme.media.laptopXL} {
    padding: 0;
    max-width: 417px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 404px;
  }
  @media (max-width: 1200px) {
    padding: 0;
    max-width: 100%;
  }
`;

export const Links = styled.ul`
  width: 100%;
  display: grid;
  gap: 12px 100px;
  grid-template-columns: 285px 1fr;
  @media ${baseTheme.media.desktopL} {
    /* grid-template-columns: 285px 104px; */
    gap: 24px 60px;
  }
  @media (max-width: 1399px) {
    gap: 24px 40px;
  }
  @media (max-width: 1300px) {
    gap: 12px 46px;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const LinkWrapper = styled.li`
  padding: 12px 0px;
  @media ${baseTheme.media.desktopL} {
    padding: 5px 0;
  }
  @media (max-width: 1300px) {
    padding: 12px 0px;
  }
  @media (max-width: 1200px) {
    padding: 5px 0;
  }
`;

export const LinkTo = styled(Link)`
  padding: 12px 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #d9d9d9;
  @media ${baseTheme.media.desktopL} {
    padding: 0;
    line-height: 130%;
  }
  @media (max-width: 1300px) {
    line-height: 100%;
  }
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

// export const LinkRef = styled.a`
//   padding: 12px 0px;
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 100%;
//   color: #d9d9d9;
//   @media ${baseTheme.media.desktopL} {
//     padding: 0;
//     line-height: 130%;
//   }
//   @media (max-width: 1300px) {
//     line-height: 100%;
//   }
//   @media (max-width: 1200px) {
//     font-size: 14px;
//   }
// `;

export const Contacts = styled.div`
  width: 100%;
  max-width: 679px;
  /* max-width: 616px; */
  max-height: 261px;
  padding-top: 12px;
  display: grid;
  grid-template-columns: 209px 1fr;
  gap: 24px 100px;
  @media ${baseTheme.media.desktopL} {
    max-width: 636px;
    gap: 24px 60px;
    padding-top: 0;
  }
  @media ${baseTheme.media.laptopXL} {
    max-width: 616px;
    gap: 24px 40px;
  }
  @media (max-width: 1300px) {
    max-width: 481px;
    grid-auto-rows: max-content;
    gap: 24px 46px;
    padding-top: 12px;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const EmailsListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Email = styled.li`
  &::before {
    content: url('/images/icons/mail.svg');
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 12px;
    @media (max-width: 1200px) {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Text = styled.p`
  color: #d9d9d9;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const BankDetails = styled(Text)`
  grid-column-start: 2;
  /* @media (max-width: 1300px) {
    display: none;
  } */
`;

export const AccordionWrapper = styled.div`
  display: none;
  @media (max-width: 1200px) {
    max-width: 519px;
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: min-content;
    row-gap: 8px;
    /* gap: 8px 10px; */
  }
  @media (max-width: 700px) {
    grid-template-columns: auto;
    & div:nth-of-type(2) {
      order: 4;
    }
    & div:nth-of-type(3) {
      order: 2;
    }
    & div:nth-of-type(4) {
      order: 3;
    }
  }
`;
