import { Link } from 'react-router-dom';
import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const Wrapper = styled.header`
  /* width: 100%;
  max-width: 1920px; */
  padding: 36px 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${baseTheme.media.desktopL} {
    padding: 36px 60px;
  }
  @media ${baseTheme.media.laptopXL} {
    padding: 36px 41px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 36px 76px;
  }
  @media ${baseTheme.media.tablet} {
    padding: 12px 23px 12px 24px;
  }
  @media ${baseTheme.media.mobileM} {
    padding: 16px;
    padding-bottom: 0;
  }
`;

export const LinksWrapper = styled.nav<{ isOpen: boolean }>`
  width: 100%;
  max-width: 596px;
  @media ${baseTheme.media.laptop} {
    position: absolute;
    top: 74px;
    left: 0;
    min-width: 150px;
    width: 20%;
    padding-top: 15px;
    padding-bottom: 15px;
    /* height: 260px; */
    background-color: ${baseTheme.colors.bg};
    /* background-color: aqua; */
    transform: ${({ isOpen }) => (isOpen ? 'translateX(24px)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    /* display: none; */
  }
  @media ${baseTheme.media.mobileL} {
    width: 30%;
    height: 50vh;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  }
`;

export const Links = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${baseTheme.media.laptop} {
    flex-direction: column;
  }
`;

export const LinkItem = styled.li`
  padding: 12px 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* @media ${baseTheme.media.laptop} {
    font-size: 20px;
  } */
`;

export const LinkRef = styled.a`
  color: #1a2226;
  /* @media ${baseTheme.media.laptop} {
    font-size: 20px;
  } */
`;

export const LinkToPage = styled(Link)`
  color: #1a2226;
  /* @media ${baseTheme.media.laptop} {
    font-size: 20px;
  } */
`;

export const User = styled.div`
  width: 100%;
  max-width: 216px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: #f7f7ff;
`;

export const ButtonWrapper = styled.div`
  max-width: 152px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  align-self: center;
  border-radius: 50%;
  @media ${baseTheme.media.laptopL} {
    width: 32px;
    height: 32px;
  }
  @media ${baseTheme.media.mobileM} {
    width: 24px;
    height: 24px;
  }
`;
