import { Icon } from './styles';
type LoaderProps = {
  width?: string;
  height?: string;
  circleStroke?: string;
  pathStroke?: string;
};

export const Loader = ({ width, height, circleStroke, pathStroke }: LoaderProps) => (
  <Icon width={width} height={height} viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='18.5' stroke={circleStroke || '#F7F7FF'} strokeWidth='3' />
    <mask id='path-2-inside-1_5638_56439' fill='white'>
      <path d='M20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60858 40 14.6957 40 20L36.9995 20C36.9995 15.4914 35.2085 11.1676 32.0205 7.97954C28.8324 4.79152 24.5085 3.0005 20 3.0005V0Z' />
    </mask>
    <path
      d='M20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60858 40 14.6957 40 20L36.9995 20C36.9995 15.4914 35.2085 11.1676 32.0205 7.97954C28.8324 4.79152 24.5085 3.0005 20 3.0005V0Z'
      stroke={pathStroke || '#475359'}
      strokeWidth='6'
      strokeLinejoin='bevel'
      mask='url(#path-2-inside-1_5638_56439)'
    />
  </Icon>
);
