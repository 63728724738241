import styled, { css, keyframes } from 'styled-components/macro';
import { baseTheme } from 'src/styles/index';

const modalOpen = keyframes`
 from {
    scale: 0.7;
    opacity: 0;
  }

  to {
    scale: 1;
    opacity: 1;
  }
`;

const modalClose = keyframes`
 from {
    scale: 1;
    opacity: 1;
  }

  to {
    scale: 0.7;
    opacity: 0;
  }
`;

const overlayOpen = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const overlayClose = keyframes`
 from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Overlay = styled.div<{ isClose?: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(3, 25, 38, 0.65);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ isClose }) =>
    isClose
      ? css`
          ${overlayClose} 0.3s ease-in-out forwards
        `
      : css`
          ${overlayOpen} 0.3s ease-in-out forwards
        `};
`;

export const Wrapper = styled.div<{ isClose?: boolean }>`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: relative; */
  /* position: absolute;
  left: 50%;
  top: 50%; */
  max-height: calc(100vh - 20px);
  /* transform: translate(-50%, -50%); */
  /* margin-bottom: 60px; */
  background-color: #f7f7ff;
  padding: 16px;
  z-index: 100;
  overflow: auto;
  animation: ${({ isClose }) =>
    isClose
      ? css`
          ${modalClose} 0.3s ease-in-out forwards
        `
      : css`
          ${modalOpen} 0.3s ease-in-out forwards
        `};
`;

export const Close = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  margin-left: auto;
  background: url('/images/icons/close.svg');
  background-repeat: no-repeat;
  &:hover {
    background-color: #e6e6e6;
  }
  &:active {
    background-color: #d9d9d9;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 48px;
  padding-top: 8px;
  @media ${baseTheme.media.tablet} {
    margin-top: 8px;
    padding: 20px;
    padding-top: 0px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 0px;
  }
`;
