import { normalize } from './normalize';
import { createGlobalStyle } from 'styled-components/macro';
import { baseTheme } from './theme';
import { Overlay } from 'src/features/ui/modal/styles';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    width: 100%;
    /* max-width: 1920px; */
    height: 100vh;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background-color: ${baseTheme.colors.bg};
}

  button {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    &:focus-visible {
    outline: 2px solid  ${baseTheme.colors.outline};
  }
}

  a {
    cursor: pointer;
    &:focus-visible {
    outline: 2px solid  ${baseTheme.colors.outline};
  }
}

`;
