import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { ButtonPrimary } from '../buttons';
import { useModalHandler } from '../modal';
import { Avatar, ButtonWrapper, User } from './style';
import { exlabAPI } from 'src/services/exlab-service';
import { logoutUser } from 'src/store/reducers/user-slice';
import { Loader } from '../loader';

export const UserBlock = () => {
  const getAuthModal = useModalHandler({ notificationType: 'AuthorizationModal' });
  const { isAuth, refreshToken } = useAppSelector((state) => state.user);
  const [logout, { isLoading, isError, error }] = exlabAPI.useLogoutMutation();

  const dispatch = useAppDispatch();

  const userNotAuth = (
    <ButtonWrapper>
      <ButtonPrimary
        isNotification
        notColored
        isHeader
        type='button'
        onClick={getAuthModal}
        text='Войти'
        isLoading={isLoading}
        loader={<Loader width='18px' height='18px' circleStroke='#b6bcbf' />}
      />
    </ButtonWrapper>
  );

  const handlerClick = () => {
    logout({ refreshToken: refreshToken });
    dispatch(logoutUser());
  };

  const userAuth = (
    <>
      <Avatar src='/images/user/avatar.jpg' loading='lazy' />
      <ButtonPrimary
        isNotification
        notColored
        isHeader
        type='button'
        onClick={handlerClick}
        text='Выйти'
        isLoading={isLoading}
        loader={<Loader width='18px' height='18px' circleStroke='#b6bcbf' />}
      />
    </>
  );
  return <User>{isAuth ? userAuth : userNotAuth}</User>;
};
