import { linksData } from '.';
import { LinkRef, LinkItem, Links, LinksWrapper, LinkToPage } from './style';

type LinkListProps = {
  isOpen: boolean;
  closeMenu: () => void;
};

export const LinkList = ({ isOpen, closeMenu }: LinkListProps) => {
  return (
    <LinksWrapper isOpen={isOpen}>
      <Links>
        {linksData.map((link) => (
          <LinkItem key={link.id}>
            {link.href ? (
              <LinkRef href={link.href} onClick={closeMenu}>
                {link.title}
              </LinkRef>
            ) : (
              <LinkToPage to={link.to!} onClick={closeMenu}>
                {link.title}
              </LinkToPage>
            )}
          </LinkItem>
        ))}
      </Links>
    </LinksWrapper>
  );
};
