import { useEffect, useState } from 'react';
import { Close, ContentContainer, Overlay, Wrapper } from './styles';

type ModalWrapperProps = {
  children: React.ReactNode;
  closeModal: (value?: boolean) => void;
};

export const ModalWrapper = ({ children, closeModal }: ModalWrapperProps) => {
  const [isCloseModal, setCloseModal] = useState(false);

  const handleCloseModal = () => {
    setCloseModal(true);
  };

  useEffect(() => {
    if (isCloseModal) {
      const timerId = setTimeout(() => {
        setCloseModal(false);
        closeModal();
      }, 300);
      return () => clearTimeout(timerId);
    }
  }, [isCloseModal, closeModal]);

  return (
    <Overlay isClose={isCloseModal}>
      <Wrapper isClose={isCloseModal}>
        <Close onClick={handleCloseModal} />
        <ContentContainer>{children}</ContentContainer>
      </Wrapper>
    </Overlay>
  );
};
