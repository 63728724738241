import { sectionForJuniorData } from '..';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';
import { Title } from '../components';
import { PrimaryTitle } from '../components/style';
import { StagesContainer, Wrapper, WrapperStages } from './style';
import { useMediaQuery } from 'src/utils/use-media-query';
import { StageCard } from './stage';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const ForJuniorSection = ({ primary, light }: ForJuniorSectionProps) => {
  const isShowSlider = useMediaQuery('(max-width: 1057px)');
  const isMobile = useMediaQuery('(min-width: 640px)');

  return (
    <Wrapper>
      <Title title='Стартап для '>
        <PrimaryTitle as='span'>Junior</PrimaryTitle>
      </Title>
      <WrapperStages>
        <StagesContainer>
          {/* {isShowSlider && isMobile ? (
          <Swiper
            scrollbar={{
              hide: true,
            }}
            spaceBetween={16}
            modules={[Scrollbar]}
            className='startUp'
          >
            {sectionForJuniorData.map((stage) => (
              <SwiperSlide key={stage.id}>
                <StageCard
                  id={stage.id}
                  title={stage.title}
                  description={stage.description}
                  number={stage.number}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          sectionForJuniorData.map((stage) => (
            <StageCard
              key={stage.id}
              id={stage.id}
              title={stage.title}
              description={stage.description}
              number={stage.number}
            />
          ))
        )} */}
          {sectionForJuniorData.map((stage) => (
            <StageCard
              key={stage.id}
              id={stage.id}
              title={stage.title}
              description={stage.description}
              number={stage.number}
            />
          ))}
        </StagesContainer>
      </WrapperStages>
    </Wrapper>
  );
};
