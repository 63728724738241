import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const BurgerButton = styled.button<{ isOpen: boolean }>`
  display: none;
  width: 22px;
  height: 22px;
  /* padding: 2px; */
  background-color: transparent;
  outline: 0;
  border: 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  span {
    position: relative;
    width: 22px;
    height: 2px;
    background-color: #1a2226;
    border-radius: 10px;
    transition: all 0.2s linear;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media ${baseTheme.media.laptop} {
    display: flex;
  }
`;
