import { EmailsListWrapper, Email, Text } from './style';

export const EmailsList = () => {
  return (
    <EmailsListWrapper>
      <Email>
        <Text as='a' href='mailto:info@exlab.team'>
          info@exlab.team
        </Text>
      </Email>
      <Email>
        <Text as='a' href='mailto:support@exlab.team'>
          support@exlab.team
        </Text>
      </Email>
    </EmailsListWrapper>
  );
};
