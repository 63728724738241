import { SectionTitle } from './style';

type TitleProps = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  padding?: boolean;
};

export const Title = ({ title, children, padding }: TitleProps) => (
  <SectionTitle padding={padding}>
    {title}
    {children}
  </SectionTitle>
);
