import { Wrapper, Label, LabelWrapper, Radio, Summ, CustomRadio, ExtrInfo } from './styles';
import { Ref, forwardRef } from 'react';

type ConditionProps = {
  name: string;
  id: string;
  htmlFor: string;
  value: number;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
  summ?: string;
  withExtra?: boolean;
};

export const RadioInput = ({
  name,
  id,
  value,
  checked,
  onChange,
  text,
  summ,
  withExtra,
}: ConditionProps) => {
  // const [topping, setTopping] = useState('Medium');
  return (
    <Wrapper htmlFor={id} checked={checked}>
      <Radio name={name} id={id} value={value} checked={checked} onChange={onChange} />
      <CustomRadio></CustomRadio>
      <LabelWrapper>
        <Label>{text}</Label>
        <Summ>{summ}</Summ>
      </LabelWrapper>
      {withExtra && <ExtrInfo>Экономия 20%</ExtrInfo>}
    </Wrapper>
  );
};
