import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const SectionTitle = styled.h1<{ padding?: boolean }>`
  padding-left: ${({ padding }) => padding && '108px'};
  color: #475359;
  /* text-align: center; */
  font-size: 80px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -1.5px; //было -2.4
  text-transform: uppercase;
  /* @media (max-width: 1890px) {
    font-size: 64px;
    line-height: 100%;
    letter-spacing: -1.28px;
  } */
  @media ${baseTheme.media.desktopL} {
    padding-left: ${({ padding }) => padding && '60px'};
    font-size: 64px;
    line-height: 100%;
  }
  @media ${baseTheme.media.laptopXL} {
    padding-left: ${({ padding }) => padding && '41px'};
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -0.96px;
  }
  @media ${baseTheme.media.laptopL} {
    padding-left: ${({ padding }) => padding && '76px'};
    font-size: 40px;
    letter-spacing: -0.8px;
  }
  @media ${baseTheme.media.tablet} {
    padding-left: ${({ padding }) => padding && '24px'};
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.64px;
  }
  @media ${baseTheme.media.mobileM} {
    padding-left: ${({ padding }) => padding && '16px'};
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

export const PrimaryTitle = styled(SectionTitle)`
  color: #ff504c;
  font-weight: 400;
`;

export const LightTitle = styled(SectionTitle)`
  color: #5f7988;
`;

export const SectionSubTitle = styled.h3<{ padding?: string }>`
  padding-bottom: ${({ padding }) => padding || '24px'};
  color: #1e323e;
  font-size: 48px;
  line-height: 120%;
  @media (max-width: 1890px) {
    font-size: 40px;
  }
  @media (max-width: 1366px) {
    font-size: 32px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 24px;
  }
  @media ${baseTheme.media.laptop} {
    font-size: 20px;
  }
`;

export const SectionElementDescription = styled.p<{ light?: boolean; order?: number }>`
  color: ${({ light }) => (light ? '#f7f7ff' : '#2f4d5f')};
  font-size: 24px;
  line-height: 140%;
  @media (max-width: 1890px) {
    font-size: 20px;
  }
  @media (max-width: 1366px) {
    font-size: 18px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 16px;
  }
  @media ${baseTheme.media.mobileL} {
    order: ${({ order }) => order};
  }
  @media ${baseTheme.media.mobileM} {
    font-size: 14px;
    line-height: 20px;
  }
  /* @media ${baseTheme.media.laptop} {
    font-size: 18px;
  } */
`;

export const ElementDescription = styled.p`
  color: #2f4d5f;
  font-size: 24px;
  line-height: 140%;
`;
