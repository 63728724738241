import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

type Mentor = {
  name: string;
  position: string;
  image: string;
  quality: Array<string>;
};

type MentorsState = {
  mentors: Mentor[];
};

const initialState: MentorsState = {
  mentors: [],
};

export const fetchMentors = createAsyncThunk('mentors/fetchMentors', async function () {
  const response = await fetch('./data/mentors.json');

  const data = await response.json();
  return data;
});

const mentorsSlice = createSlice({
  name: 'mentors',
  initialState,
  reducers: {
    initMentors(state, action) {
      state.mentors.push({
        name: action.payload.name,
        position: action.payload.position,
        image: action.payload.image,
        quality: action.payload.quality,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMentors.fulfilled, (state, action) => {
      state.mentors = action.payload;
    });
  },
});

export const { initMentors } = mentorsSlice.actions;
export default mentorsSlice.reducer;
