import { achievementsSectionData } from '..';
import { ElementDescription, Title } from '../components';
import { LightTitle, PrimaryTitle, SectionElementDescription } from '../components/style';
import { Achievement, AchievementsContainer, Amount, Wrapper } from './style';

type AchievementsSectionProps = {
  weight?: string;
};

export const AchievementsSection = ({ weight }: AchievementsSectionProps) => (
  <Wrapper>
    <LightTitle>
      Наши
      <PrimaryTitle as='span'> достижения</PrimaryTitle>
    </LightTitle>
    <AchievementsContainer>
      {achievementsSectionData.map((achievement) => (
        <Achievement key={achievement.id}>
          <Amount>{achievement.amount}</Amount>
          <SectionElementDescription>
            {achievement.description[0]}
            {<br />}
            {achievement.description[1]}
          </SectionElementDescription>
        </Achievement>
      ))}
    </AchievementsContainer>
  </Wrapper>
);
