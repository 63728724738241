import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';

export const Main = styled.main`
  width: 100%;
  height: calc(100vh - 527px);
  padding: 0 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${baseTheme.media.desktopL} {
    padding: 0 60px;
  }
  @media (max-width: 1366px) {
    height: calc(100vh - 479px);
  }
  @media ${baseTheme.media.laptopL} {
    height: calc(100vh - 424px);
    padding: 0 76px;
  }
  @media ${baseTheme.media.tablet} {
    height: calc(100vh - 330px);
    padding: 0 24px;
  }
  @media ${baseTheme.media.mobileL} {
    height: calc(100vh - 322px);
    padding: 0 16px;
  }
`;

export const Text = styled.h1`
  color: #475359;
  text-align: center;
  font-size: 80px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -2.4px;
  text-transform: uppercase;
  @media screen and (max-width: 1660px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media ${baseTheme.media.desktopL} {
    font-size: 64px;
    line-height: 100%;
    letter-spacing: -1.28px;
  }
  @media (max-width: 1366px) {
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.96px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 40px;
    letter-spacing: -0.8px;
  }
  @media ${baseTheme.media.tablet} {
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.64px;
  }
  @media ${baseTheme.media.mobileL} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

export const TextColored = styled(Text)`
  color: #ff504c;
  font-weight: 400;
`;
