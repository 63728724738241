import { LightTitle, PrimaryTitle, SectionElementDescription } from '../components/style';
import { Container, PartnersList, Wrapper, SecondPartnersList } from './style';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const PartnersSection = ({ primary, light }: ForJuniorSectionProps) => (
  <Container>
    <LightTitle>
      Наши
      <PrimaryTitle as='span'> партнеры</PrimaryTitle>
    </LightTitle>

    <Wrapper>
      <PartnersList>
        <li>
          <a href='https://www.alfabank.by/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/alfa-black.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.visaver.com/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/visaver-logo.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.alfabank.by/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/alfa-red.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.visaver.com/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/visaver-logo.png' alt='' />
          </a>
        </li>
      </PartnersList>
      <SecondPartnersList>
        <li>
          <a href='https://www.alfabank.by/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/alfa-black.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.visaver.com/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/visaver-logo.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.alfabank.by/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/alfa-red.png' alt='' />
          </a>
        </li>
        <li>
          <a href='https://www.visaver.com/' target='_blank' rel='noreferrer'>
            <img src='/images/icons/visaver-logo.png' alt='' />
          </a>
        </li>
      </SecondPartnersList>
    </Wrapper>
  </Container>
);
