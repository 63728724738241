export const sectionForJuniorData = [
  {
    id: '1',
    title: 'Присоединяйся',
    description: ['Проходи', 'регистрацию', 'и создавай аккаунт стажёра'],
    number: '01',
  },
  {
    id: '2',
    title: 'Выбирай проект',
    description: 'Подбери работу своим предпочтениям и возможностям',
    number: '02',
  },
  {
    id: '3',
    title: 'Проходи собес',
    description: 'Получай опыт прохождения технического собеседования',
    number: '03',
  },
  {
    id: '4',
    title: 'Получай опыт',
    description: 'Прокачивай свои хард и софт скиллы',
    number: '04',
  },
];

export const achievementsSectionData = [
  {
    id: '1',
    amount: '2',
    description: ['коммерческих', 'проекта'],
  },
  {
    id: '2',
    amount: '6+',
    description: ['некоммерческих', 'проектов'],
  },
  {
    id: '3',
    amount: '160+',
    description: ['стажёров', 'на проекте'],
  },
  {
    id: '4',
    amount: '700+',
    description: ['наше', 'комьюнити'],
  },
];

export const whyExlabSectionData = [
  {
    id: '1',
    title: 'Навыки',
    description:
      'С нами ты будешь развивать навыки, связанные как с технологиями, так и с личностными качествами.',
  },
  {
    id: '2',
    title: 'Опыт',
    description:
      'Присоединяясь к нам ты получаешь возможность проходить собеседование на различные проекты, начать старт карьеры в IT сфере.',
  },
  {
    id: '3',
    title: 'Команда',
    description:
      'Стань частью команды и получай поддержку внутри нее. У нас есть опытные менторы, которые помогут тебе влиться.',
  },
  {
    id: '4',
    title: 'Портфолио',
    description:
      'Собери свое портфолио с реальными проектами, которые больше ценятся работодателями.',
  },
];
