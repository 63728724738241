import { AppRouter } from 'src/features/routes';
import { Provider } from 'react-redux';
import { store } from 'src/store/store';
import { GlobalStyle } from 'src/styles';

export const App = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <AppRouter />
    </Provider>
  );
};
