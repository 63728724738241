import { whyExlabSectionData } from '..';
import { ButtonAsLink } from '../../buttons';
import { useModalHandler } from '../../modal';
import { LightTitle, PrimaryTitle, SectionElementDescription } from '../components/style';
import {
  Container,
  SubTitle,
  AboutBenefits,
  Title,
  StickerpackTitle,
  Wrapper,
  CrabImg,
  StickerpackImg,
  Hackathons,
  HackathonsTitle,
  HackathonsDescription,
  WrapperTop,
  BlockWrapper,
  EngBlockWrapper,
} from './style';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const BenefitsSection = ({ primary, light }: ForJuniorSectionProps) => (
  <Container>
    <WrapperTop>
      <AboutBenefits>
        <Title>ExLab</Title>
        <SubTitle>Жизнь, Стиль, Пространство</SubTitle>
        <SectionElementDescription light>
          У вас есть возможность участвовать в хакатонах, в клубе разговорного английского, а также
          участвовать в оффлайн встречах и проводить отлично время в кругу друзей.
        </SectionElementDescription>
      </AboutBenefits>
      <BlockWrapper>
        <a href='https://t.me/addstickers/ExLabJunGarik' target='_blank' rel='noreferrer'>
          <StickerpackImg src='/images/main-page/stickerpack.svg' alt='стикерпак' />
        </a>
        <StickerpackTitle>Стикерпак</StickerpackTitle>
        <SectionElementDescription light>
          Добавь себе стикерпак из 19 Джун Гариков
        </SectionElementDescription>
      </BlockWrapper>
    </WrapperTop>
    <Wrapper>
      <EngBlockWrapper>
        <a href='https://boosty.to/exlab_startup' target='_blank' rel='noreferrer'>
          <CrabImg src='/images/main-page/english.svg' alt='english crab' />
        </a>
        <SectionElementDescription light>
          Прокачай свой уровень английского языка в нашем разговорном клубе
        </SectionElementDescription>
      </EngBlockWrapper>
      <Hackathons>
        <HackathonsTitle>Хакатоны</HackathonsTitle>
        <HackathonsDescription light>Участвуй вместе с нами</HackathonsDescription>
      </Hackathons>
    </Wrapper>
  </Container>
);
