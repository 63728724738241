import { useModalHandler } from 'src/features/ui/modal';
import { Main } from './styles';
import { useSearchParams } from 'react-router-dom';
import { exlabAPI } from 'src/services/exlab-service';
import { useEffect } from 'react';

export const EmailVerifyPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const successfulRegistrationModal = useModalHandler({
    notificationType: 'NoticeSuccessfulRegistration',
  });

  const [verificationUser, { isLoading, isSuccess, error, isError }] =
    exlabAPI.useVerificationUserMutation();

  useEffect(() => {
    if (token) verificationUser({ token });
    else {
      // console.log('token empty');
    }
  }, [token, verificationUser]);

  useEffect(() => {
    if (isSuccess) {
      successfulRegistrationModal();
    }
    if (isError) {
      // console.log('err verify');
      // console.log(error);
    }
  }, [error, isError, isSuccess, successfulRegistrationModal, token]);

  return (
    <>
      <Main />
      {/* {token && successfulRegistrationModal()} */}
      {/* {token && <NoticeSuccessfulRegistration closeModal={() => console.log('close')} />} */}
    </>
  );
};
