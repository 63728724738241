import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalReducerState {
  isOpen: boolean;
}

export const initialState: ModalReducerState = {
  isOpen: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal(state) {
      state.isOpen = true;
    },
  },
});

export const { closeModal } = modalSlice.actions;
export const modalReduser = modalSlice.reducer;
