import { baseTheme } from 'src/styles/theme';
import styled, { css } from 'styled-components/macro';

// export const sliderStyles = css`
// `;
// export const NavigationItem = styled.div`
//   display: flex;
//   gap: 48px;
//   padding: 12px 0;
//   border-bottom: 1px solid #cdd3d7;
// `;

export const NavigationTitle = styled.h4`
  color: #1a2226;
  font-size: 24px;
  line-height: 100%;
  @media ${baseTheme.media.laptopL} {
    font-size: 20px;
  }
  /* @media ${baseTheme.media.tablet} {
    gap: 48px;
    padding: 0 24px;
  }
  @media ${baseTheme.media.mobileL} {
    min-width: 100%;
  }
  @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
  } */
`;

export const NavigationDomain = styled(NavigationTitle)`
  font-size: 18px;
  line-height: 100%;
  @media ${baseTheme.media.laptopL} {
    font-size: 16px;
  }
`;

export const SlideNumber = styled(NavigationTitle)`
  align-self: center;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  @media ${baseTheme.media.laptopL} {
    font-size: 20px;
    letter-spacing: 0.4px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  position: relative;
  .swiper {
    max-height: 552px;
    @media (max-width: 1700px) {
      max-height: 456px;
    }
    @media ${baseTheme.media.laptopL} {
      max-height: 380px;
    }
    @media (max-width: 700px) {
      max-height: 280px;
    }
  }
  .swiper-slide {
    @media (max-width: 700px) {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .swiper-slide img {
    @media (max-width: 700px) {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  /* .swiper-wrapper {
    width: 32%;
  }
  .swiper-slide {
    display: flex;
    gap: 48px;
    padding: 12px 0;
    border-bottom: 1px solid #cdd3d7;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  .navigation {
    display: flex;
    flex-direction: column;
    flex-basis: 32.7%;
    @media (max-width: 1057px) {
      flex-basis: 38%;
    }
    @media ${baseTheme.media.tablet} {
      flex-basis: 41%;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }

  .navigation .swiper-slide {
    /* height: 72px; */
    max-width: 408px;
    display: flex;
    gap: 48px;
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #cdd3d7;
    cursor: pointer;
    @media (max-width: 1700px) {
      max-width: 100%;
    }
    @media ${baseTheme.media.laptopL} {
      max-width: 284px;
      gap: 49px;
      margin-top: 8px;
      padding-bottom: 8px;
    }
    @media (max-width: 1057px) {
      max-width: 308px;
    }
    @media (max-width: 842px) {
      gap: 20px;
    }
    @media ${baseTheme.media.tablet} {
      gap: 49px;
    }
    @media ${baseTheme.media.mobileL} {
      min-width: 100%;
    }
    @media ${baseTheme.media.mobileM} {
      padding: 0 16px;
    }
  }

  .navigation .swiper-slide:hover {
    ${NavigationTitle} {
      color: #475359;
      transition: color 0.2s ease-in-out;
    }
  }

  .navigation .swiper-slide:focus-visible {
    outline: 2px solid #007dff;
  }
  .navigation .swiper-slide-thumb-active,
  .navigation .swiper-slide-thumb-active:hover {
    border-color: #5f7988;
    transition: border-color 0.3s ease-in-out;
    margin-top: 11px;
    padding-bottom: 11px;
    ${NavigationTitle} {
      color: #090b0d;
      font-weight: 500;
      /* @media ${baseTheme.media.laptopL} {
        min-width: 905px;
        gap: 40px;
        overflow-x: scroll;
        padding: 0 76px;
      } */
      /* @media ${baseTheme.media.tablet} {
        gap: 48px;
        padding: 0 24px;
      }
      @media ${baseTheme.media.mobileL} {
        min-width: 100%;
      }
      @media ${baseTheme.media.mobileM} {
        padding: 0 16px;
      } */
    }
    ${SlideNumber} {
      padding: 16px;
      color: #e3e7ea;
      background-color: #ff504c;
      letter-spacing: 0px;
      transition: padding 0.3s ease-in-out;
      @media ${baseTheme.media.laptopL} {
        padding: 20px 17px;
      }
    }
    ${NavigationDomain} {
      font-weight: 400;
    }
  }

  .sliderDescription {
    width: 66%;
    display: flex;
    /* @media (max-width: 1000px) {
      width: 51%;
    } */
    @media (max-width: 1057px) {
      width: fit-content;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }

  .sliderDescription .swiper-slide {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .mobileSlider {
    display: none;
    width: 100%;
    /* min-width: 388px; */
    overflow: hidden;
    @media (max-width: 700px) {
      display: block;
    }
  }

  .mobileSlider .swiper-slide {
    transform: scale(0.86);
    /* transition: transform 0.5s ease-in-out; */
  }
  .mobileSlider .swiper-slide-active {
    transform: scale(1);
    transition: transform 0.4s ease-in-out;
  }
  /* .mobileSlider .swiper-slide-prev,
  .mobileSlider .swiper-slide-next {
    transform: scale(0.86);
    transition: transform 0.5s ease-in-out;
  } */

  @media (max-width: 1057px) {
    justify-content: space-between;
    /* gap: 64px; */
  }
  @media (max-width: 842px) {
    gap: 10px;
  }
`;

export const SlidesWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 49%;
  @media (max-width: 700px) {
    flex-basis: 100%;
  }
`;

export const SlidePhoto = styled(Slide)<{ img: string }>`
  display: flex;
  width: 100%;
  max-width: 552px;
  min-width: 380px;
  align-items: flex-end;
  padding: 24px;
  color: ${baseTheme.colors.bg};
  font-size: 144px;
  font-weight: 500;
  line-height: 100%;
  background-color: #000;
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid #cdd3d7;
  @media (max-width: 1700px) {
    max-width: 456px;
    padding: 19px 24px;
    font-size: 120px;
  }
  @media (max-width: 1366px) {
    max-width: 412px;
    max-height: 412px;
    font-size: 96px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 380px;
    max-height: auto;
    padding: 24px;
    font-size: 72px;
  }
  @media (max-width: 700px) {
    flex-shrink: 0;
    min-width: 280px;
    height: 280px;
    border-radius: 16px;
    font-size: 32px;
  }
  @media ${baseTheme.media.mobileM} {
    max-width: 280px;
  }
`;

export const SlideDescription = styled(Slide)`
  max-width: 408px;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1700px) {
    max-width: 336px;
    align-self: center;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 284px;
    gap: 16px;
  }
  @media (max-width: 1057px) {
    display: none;
  }
`;

export const DescriptionTitle = styled.h3`
  color: #2f4d5f;
  font-size: 48px;
  line-height: 100%;
  @media (max-width: 1366px) {
    font-size: 32px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 24px;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
