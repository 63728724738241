import React, { LazyExoticComponent } from 'react';

export enum Modals {
  RegistrationModal,
  AuthorizationModal,
  ForgotPasswordModal,
  SendNewPasswordModal,
  NoticeAbortRegistration,
  NoticeCheckEmail,
  NoticeExpiredLink,
  NoticeSuccessfulRegistration,
  NoticeUserBlocked,
  NoticeCheckLinkToResetPassword,
  NoticePasswordWasChanged,
  NoticeWrongEmailForChangePassword,
  NoticeGoToAuth,
  NoticeUnknownErr,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const modalsConfig: Record<Modals, LazyExoticComponent<any>> = {
  //any as type instead of never?
  [Modals.RegistrationModal]: React.lazy(() => import('src/features/users/registration/index')),
  [Modals.AuthorizationModal]: React.lazy(() => import('src/features/users/authorization/index')),
  [Modals.ForgotPasswordModal]: React.lazy(
    () => import('src/features/users/forgot-password/index')
  ),
  [Modals.SendNewPasswordModal]: React.lazy(
    () => import('src/features/users/send-new-password/index')
  ),
  [Modals.NoticeAbortRegistration]: React.lazy(
    () => import('../notifications/notice-abort-registration')
  ),
  [Modals.NoticeCheckEmail]: React.lazy(() => import('../notifications/notice-check-email')),
  [Modals.NoticeExpiredLink]: React.lazy(() => import('../notifications/notice-expired-link')),
  [Modals.NoticeSuccessfulRegistration]: React.lazy(
    () => import('../notifications/notice-successful-registaration')
  ),
  [Modals.NoticeUserBlocked]: React.lazy(() => import('../notifications/notice-user-bloked')),
  [Modals.NoticeCheckLinkToResetPassword]: React.lazy(
    () => import('../notifications/notice-send-link-to-reset-password')
  ),
  [Modals.NoticePasswordWasChanged]: React.lazy(
    () => import('../notifications/notice-password-was-changed')
  ),
  [Modals.NoticeWrongEmailForChangePassword]: React.lazy(
    () => import('../notifications/notice-wrong-email-for-change-password')
  ),
  [Modals.NoticeGoToAuth]: React.lazy(() => import('../notifications/notice-go-to-auth')),
  [Modals.NoticeUnknownErr]: React.lazy(() => import('../notifications/notice-unknown-error')),
};
