import styled from 'styled-components/macro';
import { SectionElementDescription, SectionSubTitle } from '../components/style';
import { baseTheme } from 'src/styles/index';

export const Container = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
  /* padding-top: 62px; */
  /* padding-left: calc((100% - 1704px) / 2); */
  padding-left: 108px;
  background-color: ${baseTheme.colors.bgDark};
  @media ${baseTheme.media.desktopL} {
    /* padding-top: 64px; */
    padding-left: 60px;
  }
  @media (max-width: 1366px) {
    padding-left: 41px;
  }
  @media ${baseTheme.media.laptopL} {
    /* gap: 0; */
    padding-left: 76px;
  }
  @media ${baseTheme.media.tablet} {
    padding-left: 24px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 0;
  }
`;

export const Wrapper = styled.div<{ light?: boolean }>`
  display: flex;
  justify-content: space-between;
  /* gap: 120px; */
  /* @media ${baseTheme.media.desktopL} {
    gap: 144px;
  }
  @media (max-width: 1366px) {
    gap: 69px;
  } */
`;

export const WrapperTop = styled(Wrapper)`
  /* padding-right: calc(100% - 1704px); */
  margin-bottom: 24px;
  padding-right: 108px;
  justify-content: space-between;
  padding-top: 62px;
  padding-bottom: 62px;
  @media ${baseTheme.media.desktopL} {
    padding-right: 60px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media (max-width: 1366px) {
    padding-right: 41px;
    padding-top: 73px;
    padding-bottom: 73px;
  }
  @media ${baseTheme.media.laptopL} {
    margin-bottom: 0;
    padding-right: 76px;
    padding-bottom: 64px;
  }
  @media (max-width: 1100px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media ${baseTheme.media.tablet} {
    padding-right: 24px;
  }
  @media ${baseTheme.media.mobileL} {
    max-width: 100%;
    padding: 32px 16px 24px;
  }
`;

export const AboutBenefits = styled.div`
  display: grid;
  /* flex-direction: column; */
  align-content: center;
  gap: 24px;
  max-width: 960px;
  margin-right: 120px;
  @media ${baseTheme.media.desktopL} {
    max-width: 816px;
    margin-right: 144px;
    ${SectionElementDescription} {
      padding-top: 56px;
    }
  }
  @media (max-width: 1366px) {
    max-width: 750px;
    margin-right: 69px;
    ${SectionElementDescription} {
      padding-top: 0;
    }
  }
  @media ${baseTheme.media.laptopL} {
    gap: 16px;
    ${SectionElementDescription} {
      max-width: 662px;
    }
  }
  @media (max-width: 1100px) {
    ${SectionElementDescription} {
      max-width: 461px;
    }
  }
  @media ${baseTheme.media.mobileL} {
    /* max-width: 100%; */
    margin-right: 0;
    ${SectionElementDescription} {
      max-width: 96%;
      /* font-size: 14px; */
    }
  }
  @media ${baseTheme.media.mobileM} {
    ${SectionElementDescription} {
      max-width: 96%;
      font-size: 14px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 80px;
  line-height: 75%;
  color: #ff3c38;
  @media ${baseTheme.media.desktopL} {
    font-size: 64px;
    line-height: 100%;
  }
  @media (max-width: 1366px) {
    font-size: 48px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 40px;
    line-height: 75%;
  }
  @media (max-width: 1100px) {
    font-size: 32px;
  }
  @media ${baseTheme.media.mobileM} {
    font-size: 24px;
  }
`;

export const SubTitle = styled(SectionSubTitle)`
  padding-bottom: 0px;
  color: #f7f7ff;
  @media (max-width: 1100px) {
    font-size: 20px;
  }
  @media ${baseTheme.media.mobileM} {
    font-size: 18px;
  }
`;

export const BlockWrapper = styled.div`
  display: grid;
  /* flex-direction: column; */
  align-content: center;
  justify-items: center;
  gap: 24px;
  max-width: 277px;
  margin-right: 51px;
  /* padding-bottom: 62px; */
  text-align: center;
  @media ${baseTheme.media.desktopL} {
    max-width: 231px;
    margin-right: 0;
    /* padding-bottom: 64px; */
  }
  @media ${baseTheme.media.laptopL} {
    align-items: center;
    max-width: 379px;
    gap: 10px;
    ${SectionElementDescription} {
      max-width: 215px;
    }
  }
  @media (max-width: 1100px) {
    max-width: 188px;
    gap: 8px;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const EngBlockWrapper = styled(BlockWrapper)`
  /* justify-items: center; */
  max-width: 456px;
  margin-right: 120px;
  padding: 48px;
  @media ${baseTheme.media.desktopL} {
    margin-right: 144px;
  }
  @media (max-width: 1366px) {
    margin-right: 69px;
  }
  @media ${baseTheme.media.laptopL} {
    gap: 24px;
    max-width: 360px;
    padding: 0;
    ${SectionElementDescription} {
      max-width: 95%;
    }
  }
  @media (max-width: 1100px) {
    max-width: 215px;
    gap: 12px;
    ${SectionElementDescription} {
      max-width: 100%;
    }
  }
  @media ${baseTheme.media.tablet} {
    margin-left: 36px;
    margin-right: 0px;
  }
`;

export const StickerpackTitle = styled(SubTitle)`
  line-height: 100%;
`;

export const StickerpackImg = styled.img`
  width: 223px;
  height: 252px;
  margin-bottom: 24px;
  @media ${baseTheme.media.laptopL} {
    margin: 0 78px 22px 78px;
  }
  @media (max-width: 1100px) {
    width: 188px;
    height: 200px;
    margin: 0;
    margin-bottom: 16px;
  }
`;

export const CrabImg = styled.img`
  width: 268px;
  height: 335px;
  @media ${baseTheme.media.laptopL} {
    width: 240px;
    height: 300px;
  }
  @media (max-width: 1100px) {
    width: 160px;
    height: 200px;
  }
`;

export const Hackathons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* gap: 8px; */
  width: 100%;
  height: 556px;
  padding: 48px;
  background-image: url('/images/main-page/hackathons-bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media ${baseTheme.media.desktopL} {
    height: 552px;
    background-position-x: 72%;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 740px;
    height: 480px;
    padding: 32px;
  }
  @media (max-width: 1100px) {
    height: 321px;
    padding: 24px;
    /* gap: 6px; */
  }
  @media ${baseTheme.media.tablet} {
    max-width: 465px;
  }
  @media ${baseTheme.media.mobileM} {
    height: 261px;
    padding: 16px;
    /* gap: 8px; */
  }
`;

export const HackathonsTitle = styled(SubTitle)`
  margin-bottom: 8px;
  font-weight: 700;
  @media ${baseTheme.media.laptopL} {
    font-size: 32px;
  }
  @media (max-width: 1100px) {
    margin-bottom: 8px;
    font-size: 24px;
  }
  @media ${baseTheme.media.mobileM} {
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

export const HackathonsDescription = styled(SectionElementDescription)`
  font-weight: 500;
  line-height: 120%;
  @media ${baseTheme.media.laptopL} {
    font-size: 20px;
  }
  @media (max-width: 1100px) {
    font-size: 18px;
  }
  @media ${baseTheme.media.mobileM} {
    font-size: 16px;
  }
`;
