export const baseTheme = {
  colors: {
    primary500: '#ff504c',
    primary600: '#ff3c38',

    secondary: '#1a2226', //dark bg

    success: '#33c163', //green border
    danger: '#e80000', //red border

    bg: '#f7f7ff',
    bgDark: '#1a2226',
    font: '#2f4d5f',
    outline: '#007dff',
  },

  breakpoints: {
    xs: '320px',
    sm: '640px',
    md: '800px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },

  media: {
    mobileS: '(max-width: 360px)',
    mobileM: '(max-width: 430px)',
    mobileL: '(max-width: 640px)',
    tablet: '(max-width: 800px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1280px)',
    laptopXL: '(max-width: 1366px)',
    desktop: '(max-width: 1440px)',
    desktopL: '(max-width: 1536px)',
  },

  // in px
  sizes: {
    header: { height: 56 },
    container: { width: 1200 },
    footer: { height: 128 },
    modal: { width: 540 },
  },

  // in ms
  durations: {
    ms300: 300,
  },

  // z-index
  order: {
    header: 50,
    modal: 100,
  },
};
