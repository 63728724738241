import styled from 'styled-components/macro';
import { Button } from '../../buttons/button-primary/styles';
import { baseTheme } from 'src/styles/theme';
import { SectionSubTitle } from '../components/style';

export const Wrapper = styled.section<{ light?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding-left: 108px;
  @media ${baseTheme.media.desktopL} {
    padding-left: 59px;
    gap: 15px;
  }
  @media ${baseTheme.media.laptopXL} {
    padding-left: 41px;
  }
  @media ${baseTheme.media.laptopL} {
    padding-left: 76px;
  }
  @media ${baseTheme.media.tablet} {
    padding-left: 24px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 24px 16px;
    width: 100%;
    min-height: 300px;
    height: auto;
    background-image: url('/images/main-page/main-section/main-mobile.jpg');
    background-size: cover;
    background-position: center;
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('/images/main-page/main-section/main-mobile-2x.jpg');
    }
  }
  @media ${baseTheme.media.mobileM} {
    min-height: 185px;
  }
`;

export const PhotoContainer = styled.div`
  flex: 0 1 52.3%;
  @media ${baseTheme.media.desktopL} {
    flex: 0 1 51.3%;
    /* width: 100%; */
    overflow: hidden;
    /* display: flex; */
  }
  @media ${baseTheme.media.laptopXL} {
    flex-basis: 50.6%;
  }
  @media ${baseTheme.media.laptopL} {
    flex-basis: 52.2%;
  }
  @media ${baseTheme.media.tablet} {
    flex-basis: 48.97%;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const PrimePhoto = styled.img`
  /* max-width: 804px;
  max-height: 696px; */
  /* display: block;
  width: 100%;
  height: 100%;
  max-width: 804px;
  max-height: 696px;
  object-fit: contain;
  object-position: left; */
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  @media ${baseTheme.media.desktopL} {
    /* width: 100%;
    height: 624px; */
    width: auto;
    height: auto;
  }
  @media ${baseTheme.media.laptopXL} {
    width: auto;
    height: auto;
  }
  @media ${baseTheme.media.laptopL} {
    /* max-width: 628px;
    height: 480px; */
    width: auto;
    height: auto;
  }
  @media (max-width: 1133px) {
    /* width: 100%;
    height: auto; */
    width: auto;
    height: auto;
  }
  @media ${baseTheme.media.laptop} {
    /* width: 372px;
    height: 326px; */
    width: auto;
    height: auto;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* @media ${baseTheme.media.laptop} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row: 1 / 2;
    gap: 0;
    padding: 0;
    padding-top: 48px;
    ${SectionSubTitle} {
      font-size: 18px;
    }
  } */
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const Title = styled.h1<{ primary?: boolean; light?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -4px;
  text-transform: uppercase;
  color: ${baseTheme.colors.secondary};
  @media ${baseTheme.media.desktopL} {
    font-size: 68px;
  }
  @media ${baseTheme.media.laptopXL} {
    font-size: 52px;
    letter-spacing: -2.6px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 48px;
    letter-spacing: -2px;
  }
  @media (max-width: 1133px) {
    font-size: 34px;
    letter-spacing: -1.7px;
  }
  /* @media ${baseTheme.media.laptop} {
    font-size: 32px;
    letter-spacing: -1.6px;
  } */
`;

export const PartTitle = styled.span`
  &:first-child {
    margin-bottom: 8px;
  }
  &:nth-child(2) {
    display: flex;
    align-items: center;
  }
  @media ${baseTheme.media.laptopXL} {
    &:first-child {
      margin-bottom: 7px;
    }
  }

  /* @media ${baseTheme.media.laptop} {
    font-size: 32px;
    letter-spacing: -1.6px;
  } */
`;

export const SubTitle = styled.h2`
  margin-bottom: 44px;
  font-size: 44px;
  line-height: 100%;
  color: #475359;
  @media ${baseTheme.media.desktopL} {
    margin-bottom: 42px;
    font-size: 42px;
    line-height: 120%;
  }
  @media ${baseTheme.media.laptopXL} {
    margin-bottom: 28px;
    font-size: 36px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 40px;
    letter-spacing: -2px;
  }
  @media (max-width: 1133px) {
    font-size: 20px;
  }
  @media ${baseTheme.media.tablet} {
    margin-bottom: 19px;
    letter-spacing: 0;
  }
`;

export const Icon = styled.span`
  display: inline-block;
  margin-left: 20px;
  width: 58px;
  height: 58px;
  background-image: url('/images/icons/icon-arrow.svg');
  background-repeat: no-repeat;
  background-size: cover;
  @media ${baseTheme.media.desktopL} {
    width: 52px;
    height: 53px;
  }
  @media ${baseTheme.media.laptopXL} {
    width: 37px;
    height: 37px;
  }
  @media ${baseTheme.media.laptopL} {
    width: 35px;
    height: 35px;
  }
  @media ${baseTheme.media.laptop} {
    margin-left: 7px;
    width: 27px;
    height: 27px;
  }
  @media ${baseTheme.media.mobileL} {
    width: 14px;
    height: 14px;
    filter: invert(100%) sepia(0%) saturate(17%) hue-rotate(268deg) brightness(104%) contrast(103%);
  }
`;

export const MainButton = styled(Button)`
  max-width: 408px;
  margin-top: 81px;
  margin-bottom: 0;
  padding: 20px 82px;
  border-radius: 0px;
  background-color: #fa0000;
  font-size: 30px;
  line-height: 130%;
  cursor: pointer;
  @media ${baseTheme.media.desktopL} {
    max-width: 336px;
    margin-top: 87px;
    padding: 21px 54px;
    font-size: 28px;
  }
  @media ${baseTheme.media.laptopXL} {
    max-width: 303px;
    margin-top: 57px;
    padding: 12px 54px;
    font-size: 24px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 264px;
    margin-top: 76px;
    padding: 12px 42px;
    font-size: 22px;
  }
  @media ${baseTheme.media.tablet} {
    max-width: 216px;
    padding: 11px 43px;
    margin-top: 41px;
    font-size: 16px;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const MobileTitle = styled(Title)`
  display: none;
  align-self: flex-end;
  @media ${baseTheme.media.mobileL} {
    display: block;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.9px;
    color: #ffffff;
  }
`;
