import { LinkLogo } from './styles';
type LogoProps = {
  fill: string;
  // width?: string;
  // height?: string;
};

export const Logo = ({ fill }: LogoProps) => (
  <LinkLogo to='/'>
    <svg xmlns='http://www.w3.org/2000/svg' width='96' height='48' viewBox='0 0 96 48' fill='none'>
      <path
        d='M47.0488 41.9224V34.6306H50.2003C50.6927 34.62 51.1804 34.7289 51.6214 34.948C52.0191 35.1511 52.3489 35.4656 52.5704 35.853C52.8043 36.2658 52.9215 36.7343 52.9095 37.2085C52.9211 37.6837 52.8008 38.1528 52.5618 38.5639C52.332 38.9471 51.9946 39.2544 51.5914 39.4475C51.1347 39.6652 50.6332 39.7724 50.1273 39.7606H48.2467V38.2207H49.7151C49.9211 38.2264 50.1255 38.1838 50.3119 38.0963C50.4726 38.0213 50.6061 37.8985 50.6941 37.7446C50.7867 37.5814 50.8327 37.396 50.8271 37.2085C50.8332 37.0209 50.7871 36.8353 50.6941 36.6723C50.6047 36.5216 50.4714 36.4019 50.3119 36.3291C50.1244 36.2453 49.9205 36.2043 49.7151 36.209H49.0196V41.9267L47.0488 41.9224Z'
        fill='#FF3C38'
      />
      <path
        d='M53.6396 41.9222V34.6304H58.9036V36.226H55.619V37.4785H58.6245V39.0741H55.619V40.3308H58.8864V41.9264L53.6396 41.9222Z'
        fill='#FF3C38'
      />
      <path
        d='M59.8867 41.9225V34.6307H63.0253C63.5155 34.6196 64.0017 34.7208 64.4465 34.9267C64.8409 35.1125 65.1711 35.4111 65.3954 35.7845C65.6314 36.1886 65.7489 36.6509 65.7346 37.1185C65.7501 37.5833 65.6292 38.0425 65.3868 38.4396C65.1511 38.8017 64.8113 39.0843 64.4122 39.2503C63.9502 39.443 63.4528 39.5365 62.9523 39.5248H61.0717V37.9721H62.5531C62.7555 37.9789 62.9576 37.9498 63.1499 37.8863C63.3044 37.8377 63.4387 37.7398 63.532 37.6075C63.6272 37.4579 63.6737 37.2826 63.6651 37.1056C63.6755 36.9257 63.6289 36.7472 63.532 36.5952C63.4401 36.4578 63.306 36.354 63.1499 36.2992C62.9581 36.2328 62.756 36.2009 62.5531 36.2049H61.8532V41.9225H59.8867ZM64.1803 38.5726L66.0094 41.9225H63.8626L62.0808 38.5726H64.1803Z'
        fill='#FF3C38'
      />
      <path d='M68.5725 34.626V41.9178H66.5889V34.626H68.5725Z' fill='#FF3C38' />
      <path
        d='M69.5137 41.9222V34.6304H74.7776V36.226H71.4973V37.4785H74.5028V39.0741H71.4973V40.3308H74.7647V41.9264L69.5137 41.9222Z'
        fill='#FF3C38'
      />
      <path
        d='M82.0799 34.626V41.9178H80.4269L77.7863 38.0831H77.7434V41.9178H75.7598V34.626H77.4428L80.0404 38.4434H80.0963V34.626H82.0799Z'
        fill='#FF3C38'
      />
      <path
        d='M89.8386 37.3632H87.8292C87.818 37.2064 87.7803 37.0527 87.7176 36.9085C87.6588 36.7759 87.5742 36.6563 87.4686 36.5568C87.3559 36.4564 87.2246 36.3792 87.0821 36.3295C86.919 36.2743 86.7477 36.2467 86.5755 36.248C86.2704 36.2355 85.9694 36.3212 85.7168 36.4925C85.4767 36.6657 85.2954 36.9082 85.1973 37.1873C85.0708 37.5348 85.011 37.9029 85.0212 38.2725C85.0102 38.6537 85.0714 39.0335 85.2016 39.392C85.3003 39.6662 85.4818 39.903 85.7211 40.0697C85.9671 40.2258 86.2541 40.3049 86.5454 40.2971C86.7125 40.2983 86.8788 40.2752 87.0392 40.2284C87.1765 40.1849 87.3045 40.1166 87.4171 40.0268C87.5246 39.9378 87.6136 39.8285 87.679 39.7051C87.7496 39.5713 87.7961 39.4261 87.8163 39.2762H89.8257C89.7995 39.604 89.7121 39.9241 89.5681 40.2198C89.4086 40.5497 89.192 40.8489 88.9283 41.1034C88.6346 41.3845 88.289 41.6059 87.9108 41.7554C87.3909 41.9446 86.8378 42.0255 86.2854 41.993C85.7331 41.9605 85.1933 41.8155 84.6992 41.5667C84.1629 41.2699 83.7255 40.8225 83.4412 40.2799C83.118 39.6459 82.96 38.9408 82.9818 38.2296C82.9581 37.5159 83.1193 36.8082 83.4497 36.175C83.7469 35.6372 84.1945 35.1975 84.7378 34.9097C85.2868 34.621 85.8993 34.4735 86.5197 34.4808C86.9563 34.4754 87.391 34.5391 87.8077 34.6695C88.1782 34.7895 88.5233 34.9772 88.8253 35.2228C89.1203 35.4668 89.3615 35.7691 89.5338 36.1107C89.7166 36.5042 89.8202 36.9298 89.8386 37.3632Z'
        fill='#FF3C38'
      />
      <path
        d='M90.7363 41.9222V34.6304H96.0003V36.226H92.7199V37.4785H95.7254V39.0741H92.7199V40.3308H95.9874V41.9264L90.7363 41.9222Z'
        fill='#FF3C38'
      />
      <path d='M47.0234 33.254V15.2733H51.9138V29.3207H59.2129V33.254H47.0234Z' fill={fill} />
      <path
        d='M65.8331 33.254H60.5605L66.5028 15.2733H73.1837L79.1259 33.254H73.8491L69.8948 20.2232H69.7531L65.8331 33.254ZM64.8498 26.1595H74.7637V29.814H64.8498V26.1595Z'
        fill={fill}
      />
      <path
        d='M80.8477 33.2537V15.2731H88.6533C89.8227 15.2434 90.9874 15.4311 92.0882 15.8264C92.9332 16.1277 93.6708 16.6706 94.2092 17.3877C94.694 18.0855 94.9451 18.9189 94.9263 19.7682C94.9364 20.3979 94.7888 21.0201 94.4969 21.5783C94.2069 22.1235 93.7902 22.5912 93.2818 22.9423C92.7309 23.3183 92.1082 23.5768 91.4528 23.7015V23.8774C92.1925 23.8974 92.9163 24.0976 93.561 24.4607C94.1877 24.8126 94.7093 25.3248 95.0722 25.9448C95.4601 26.6183 95.6547 27.3856 95.6347 28.1624C95.6507 29.095 95.3827 30.0104 94.8661 30.7874C94.3212 31.5811 93.5661 32.2078 92.6851 32.5975C91.62 33.0594 90.4667 33.2834 89.306 33.2537H80.8477ZM85.7337 22.5305H87.7389C88.1262 22.5369 88.511 22.4685 88.8723 22.3289C89.1775 22.2133 89.4449 22.0159 89.6452 21.7585C89.8361 21.4847 89.9339 21.1569 89.9243 20.8234C89.9389 20.5759 89.8916 20.3287 89.7867 20.104C89.6817 19.8794 89.5224 19.6844 89.3232 19.5366C88.88 19.2322 88.349 19.0815 87.8118 19.1077H85.738L85.7337 22.5305ZM85.7337 29.3419H88.0179C88.6666 29.3896 89.3135 29.2288 89.8642 28.883C90.0704 28.7285 90.2362 28.5265 90.3476 28.2943C90.459 28.062 90.5126 27.8064 90.5039 27.549C90.516 27.1704 90.4143 26.797 90.2119 26.4767C90.01 26.1783 89.7249 25.9456 89.3919 25.8075C88.9822 25.646 88.5441 25.5687 88.1038 25.5802H85.7466L85.7337 29.3419Z'
        fill={fill}
      />
      <path
        d='M35.2674 22.4746L45.7566 6.55272V5H36.1347L30.2482 14.7238H30.025L24.1342 5H22.8891H14.5123H11.4467H0V23.474V41.9222H11.6699H13.2027V40.5882L17.7797 33.5752H16.2898H9.24407V30.2639V26.9783H15.6243H22.0003V22.8048V18.6314H15.6243H9.24407V15.9892V13.347H16.0666H19.0033L25.0015 22.4746L17.7797 33.5752H23.3141V37.7487V41.9222H23.4687L30.025 30.4183H30.2482L37.0235 41.9222H47.0704V40.5882L35.2674 22.4746Z'
        fill='#FF3C38'
      />
    </svg>
  </LinkLogo>
);
