import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RatesResp } from './types';

export const alfaAPI = createApi({
  reducerPath: 'developerhub.alfabank.by:8273',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://developerhub.alfabank.by:8273' }),
  endpoints: (build) => ({
    getRates: build.query<RatesResp, void>({
      query: () => 'partner/1.0.1/public/nationalRates?currencyCode=840',
    }),
  }),
});
