import { SectionElementDescription } from './style';

type ElementDescriptionProps = {
  light?: boolean;
  order?: number;
  description: string | string[];
};

export const ElementDescription = ({ description, light, order }: ElementDescriptionProps) => (
  <SectionElementDescription light={light} order={order}>
    {description}
  </SectionElementDescription>
);
