import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.section<{ light?: boolean }>`
  position: relative;
  top: 50%;
  left: 50%;
`;

const spinner = keyframes`
  from {
      transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const Icon = styled.svg<{
  width?: string;
  height?: string;
  circleStroke?: string;
  pathStroke?: string;
}>`
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  animation: ${spinner} 1.2s linear infinite;
  fill: none;
  /* circle {
    stroke: ${({ circleStroke }) => circleStroke || '#f7f7ff'};
  }
  path {
    stroke: ${({ pathStroke }) => pathStroke || '#475359'};
  } */
`;
