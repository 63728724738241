import styled from 'styled-components/macro';
import { baseTheme } from 'src/styles/index';

export const Button = styled.button<{
  isNotification?: boolean;
  notColored?: boolean;
  isHeader?: boolean;
  weight?: boolean;
}>`
  width: 100%;
  margin-top: ${({ isHeader }) => (isHeader ? '0px' : '40px')};
  margin-bottom: ${({ isNotification }) => (isNotification ? '0px' : '40px')};
  padding: ${({ isHeader }) => (isHeader ? '15px 48px' : '24px 48px')};
  font-size: ${({ isHeader }) => (isHeader ? '18px' : '24px')};
  font-weight: ${({ weight }) => (weight ? '500' : '400')};
  text-align: center;
  color: ${({ notColored }) => (notColored ? '#1a2226' : `${baseTheme.colors.bg}`)};
  background-color: ${({ notColored }) => (notColored ? `${baseTheme.colors.bg}` : '#ff3c38')};
  border: ${({ notColored }) => (notColored ? '1px solid #7e878c' : 'none')};
  &:hover {
    background-color: ${({ notColored }) => (notColored ? '#e6e6e6' : '#d0000a')};
  }
  &:active {
    background-color: ${({ notColored }) => (notColored ? '#d9d9d9' : '#ac0602')};
  }
  @media ${baseTheme.media.laptopL} {
    margin-top: ${({ isHeader }) => (isHeader ? '0px' : '32px')};
    margin-bottom: ${({ isNotification }) => (isNotification ? '0px' : '32px')};
  }
  @media ${baseTheme.media.tablet} {
    font-size: ${({ isHeader }) => (isHeader ? '18px' : '18px')};
  }
`;

export const NotificationBtn = styled(Button)`
  margin-bottom: 0px;
  border-radius: 0px;
`;

export const SecondNotificationBtn = styled(NotificationBtn)`
  color: #1a2226;
  background: #f7f7ff;
  border: 1px solid #7e878c;
`;

export const NavLinkBtn = styled(Button)`
  margin-bottom: 0px;
  color: #1a2226;
  background-color: #f7f7ff;
  border: 1px solid #7e878c;
  border-radius: 0px;
`;
