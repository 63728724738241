import { Slider } from '../../slider/portfolio-slider';
import { LightTitle, PrimaryTitle } from '../components/style';
import { slidesData } from './data';
import { Container } from './style';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const PortfolioSection = ({ primary, light }: ForJuniorSectionProps) => (
  <Container id='projects'>
    <LightTitle>
      Портфолио
      <PrimaryTitle as='span'> проектов</PrimaryTitle>
    </LightTitle>
    <Slider sliderData={slidesData} />
  </Container>
);
