import { BankDetails } from './style';

export const BankInfo = () => {
  return (
    <BankDetails>
      Банковские реквизиты: <br /> р/с BY86 ALFA 3012 2D35 9900 1027 0000 <br /> в ЗАО «Альфа-Банк»
      <br />
      БИК: ALFABY2X
    </BankDetails>
  );
};
