import { configureStore } from '@reduxjs/toolkit';
import mentorsReducer from './mentorsReducer';
import { exlabAPI } from '../services/exlab-service';
import { userReduser } from './reducers/user-slice';
import { modalReduser } from './reducers/modal-slice';
import { alfaAPI } from 'src/services/rates-service';

export const store = configureStore({
  reducer: {
    [exlabAPI.reducerPath]: exlabAPI.reducer,
    [alfaAPI.reducerPath]: alfaAPI.reducer,
    mentors: mentorsReducer,
    user: userReduser,
    modal: modalReduser,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(exlabAPI.middleware, alfaAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
