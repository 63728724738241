import { useState } from 'react';
import { LinkList, UserBlock } from '.';
import { BurgerMenu } from '../buttons';
import { Logo } from '../logo';
import { Wrapper } from './style';

export const Header = () => {
  const [isOpen, toggleBurgerMenu] = useState<boolean>(false);
  const closeMenu = () => toggleBurgerMenu(false);
  const handleClick = () => toggleBurgerMenu(!isOpen);

  return (
    <Wrapper>
      <BurgerMenu isOpen={isOpen} toggleBurgerMenu={handleClick} closeMenu={closeMenu} />
      <Logo fill='#031926' />
      <LinkList isOpen={isOpen} closeMenu={closeMenu} />
      <UserBlock />
    </Wrapper>
  );
};
