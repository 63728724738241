import { LinkTo, Links, LinksWrapper, LinkWrapper } from './style';
import { baseTheme } from 'src/styles';
import { useMediaQuery } from 'src/utils/use-media-query';

export type Link = {
  id: string;
  href?: string;
  to?: string;
  title: string;
  titleShort?: string;
};
export type LinksListProps = {
  list: Link[];
};

export const LinksList = ({ list }: LinksListProps) => {
  const isDesktopL = useMediaQuery(`${baseTheme.media.desktopL}`);

  return (
    <LinksWrapper>
      <Links>
        {list.map((link) => (
          <LinkWrapper key={link.id}>
            {link.href ? (
              <LinkTo as='a' href={link.href} target='_blank'>
                {isDesktopL
                  ? link.titleShort
                    ? link.titleShort.toUpperCase()
                    : link.title
                  : link.title}
              </LinkTo>
            ) : (
              <LinkTo to={link.to!}>
                {isDesktopL
                  ? link.titleShort
                    ? link.titleShort.toUpperCase()
                    : link.title
                  : link.title}
              </LinkTo>
            )}
          </LinkWrapper>
        ))}
      </Links>
    </LinksWrapper>
  );
};
