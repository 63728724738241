import styled from 'styled-components/macro';
import { baseTheme } from 'src/styles/index';

export const Wrapper = styled.label<{ checked?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 28px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ checked }) => (checked ? '#0f2938' : '#64737c')};
  @media ${baseTheme.media.laptopXL} {
    padding: 18px 24px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 18px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 14px 14px 14px 8px;
  }
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const CustomRadio = styled.span<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 10px;
  border: 2px solid #0f2938;
  border-radius: 50%;
  background-color: #fafaff;
  cursor: pointer;
  @media ${baseTheme.media.mobileL} {
    width: 20px;
    height: 20px;
    margin: 8px;
  }
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #0f2938;
    border-radius: 50%;
    transform: scale(0);
    ${Radio}:checked + & {
      transform: scale(1);
    }
    transition: transform 0.2s ease;
    @media ${baseTheme.media.mobileL} {
      width: 10px;
      height: 10px;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @media ${baseTheme.media.laptopL} {
    margin-left: 34px;
  }
  @media ${baseTheme.media.mobileL} {
    margin-left: 20px;
  }
`;

export const Label = styled.p`
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  font-size: 30px;
  line-height: 120%;
  color: #031926;
  @media ${baseTheme.media.desktopL} {
    font-size: 28px;
  }
  @media ${baseTheme.media.laptopXL} {
    margin-bottom: 13px;
    font-size: 24px;
    line-height: 100%;
  }
  @media ${baseTheme.media.laptopL} {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 116%;
  }
  @media ${baseTheme.media.tablet} {
    margin-bottom: 9px;
  }
  @media ${baseTheme.media.mobileL} {
    font-size: 18px;
    /* line-height: 116%; */
  }
`;

export const Summ = styled.span`
  font-size: 22px;
  line-height: 100%;
  color: #031926;
  @media ${baseTheme.media.desktopL} {
    font-size: 20px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 18px;
  }
  @media ${baseTheme.media.mobileL} {
    font-size: 14px;
    /* line-height: 116%; */
  }
`;

export const ExtrInfo = styled.p`
  margin-left: auto;
  padding: 15px 20px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  background-color: #090b0d;
  @media ${baseTheme.media.laptopXL} {
    font-size: 18px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 16px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 10px 15px;
    font-size: 14px;
  }
  @media ${baseTheme.media.mobileS} {
    font-size: 12px;
  }
`;
