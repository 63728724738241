import { NavLink } from 'react-router-dom';
import { baseTheme } from 'src/styles';
import styled from 'styled-components';

export const LinkLogo = styled(NavLink)`
  width: 216px;
  height: 48px;
  svg {
    @media ${baseTheme.media.tablet} {
      width: 77px;
      height: 32px;
    }
  }
  @media ${baseTheme.media.tablet} {
    width: 77px;
    height: 32px;
  }
`;

// export const LinkFooterBig = styled(NavLink)`
//   @media ${baseTheme.media.tablet} {
//     width: 96px;
//     height: 48px;
//   }
// `;
