import { baseTheme } from 'src/styles';
import styled from 'styled-components/macro';
import { LightTitle } from '../components/style';

export const Container = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 108px;
  gap: 120px;
  @media ${baseTheme.media.desktopL} {
    /* padding-top: 64px; */
    gap: 100px;
    padding: 0 60px;
  }
  @media (max-width: 1366px) {
    padding: 0 41px;
    gap: 68px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 0 76px;
    gap: 40px;
  }
  @media ${baseTheme.media.tablet} {
    gap: 48px;
    padding: 0 24px;
  }
  @media (max-width: 700px) {
    padding: 0;
    gap: 32px;
    ${LightTitle} {
      padding-left: 24px;
    }
  }
  @media ${baseTheme.media.mobileL} {
    gap: 24px;
  }
`;
