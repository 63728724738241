import {
  AchievementsSection,
  BenefitsSection,
  ForJuniorSection,
  MainScreen,
  PartnersSection,
  PortfolioSection,
  SubscriptionSection,
  WhyExlabrSection,
} from 'src/features/ui/main-page-section';
import { Main } from './styles';

export const MainPage = () => (
  <Main>
    <MainScreen />
    <ForJuniorSection />
    <WhyExlabrSection />
    <AchievementsSection />
    <PortfolioSection />
    <BenefitsSection />
    <SubscriptionSection />
    <PartnersSection />
  </Main>
);
