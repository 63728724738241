import { useEffect, useState } from 'react';
import { footerData, linksData } from '.';
import { Logo } from '../logo';
import {
  Contacts,
  Container,
  Copyright,
  Wrapper,
  Text,
  BankDetails,
  AccordionWrapper,
} from './style';
import { useMediaQuery } from 'src/utils/use-media-query';
import { Accordion } from '../accordion';
import { LinksList } from './links-list';
import { EmailsList } from './emails-list';
import { BankInfo } from './bank-details';

export const Footer = () => {
  const isLaptopL = useMediaQuery('(max-width: 1300px)');
  const isMoreThanTablet = useMediaQuery('(min-width: 1301px)');
  const isTablet1 = useMediaQuery('(min-width: 1201px)');
  const isTablet = useMediaQuery('(min-width: 701px)');
  // const isBigLogo = useMediaQuery('(max-width: 800px)');

  return (
    <Wrapper>
      <Container>
        {isTablet && <Logo fill='#DCDEE7' />}
        <Copyright>
          <span>&copy;</span>
          <span> 2022-2024. ExLab All</span> Rights Reserved
        </Copyright>
      </Container>
      {isTablet1 && <LinksList list={linksData} />}
      <Contacts>
        <Text>Контакты</Text>
        <Text>ООО «ЭкспириенсЛаб»</Text>
        <EmailsList />
        <Text>
          ул. Платонова, № 20Б-141 <br /> 220005, г. Минск, <br /> Республика Беларусь <br />
          УНП 193685815
        </Text>
        {isTablet1 && <BankInfo />}
      </Contacts>
      <AccordionWrapper>
        {footerData.map((item) => {
          if (Array.isArray(item.content)) {
            return (
              <Accordion key={item.id} title={item.title}>
                <LinksList list={item.content} />
              </Accordion>
            );
          } else {
            return (
              <Accordion key={item.id} title={item.title}>
                {<item.content />}
              </Accordion>
            );
          }
        })}
      </AccordionWrapper>
    </Wrapper>
  );
};
