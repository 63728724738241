import styled from 'styled-components/macro';
import {
  LightTitle,
  PrimaryTitle,
  SectionElementDescription,
  SectionSubTitle,
  SectionTitle,
} from '../components/style';
import { baseTheme } from 'src/styles/index';
import { Button } from '../../buttons/button-primary/styles';

export const Container = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* gap: 96px; */
  padding: 0 108px;
  @media ${baseTheme.media.desktopL} {
    padding: 0 60px;
    ${LightTitle}, ${PrimaryTitle} {
      font-size: 80px;
      line-height: 150%;
    }
  }
  @media ${baseTheme.media.laptopXL} {
    padding: 0 41px;
    padding-bottom: 14px;
    ${LightTitle}, ${PrimaryTitle} {
      font-size: 48px;
    }
  }
  @media ${baseTheme.media.laptopL} {
    padding: 0 76px;
  }
  @media ${baseTheme.media.tablet} {
    padding: 0 24px;
    ${LightTitle}, ${PrimaryTitle} {
      font-size: 32px;
      line-height: 100%;
    }
  }
  @media ${baseTheme.media.mobileM} {
    padding: 0 16px;
    ${LightTitle}, ${PrimaryTitle} {
      font-size: 24px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 88px;
  @media ${baseTheme.media.desktopL} {
    margin-top: 74px;
  }
  @media ${baseTheme.media.laptopXL} {
    margin-top: 47px; //по макету так
    /* margin-top: 53px; */
  }
  @media ${baseTheme.media.laptopL} {
    margin-top: 28px;
  }
  @media ${baseTheme.media.laptop} {
    flex-direction: column;
  }
  @media ${baseTheme.media.tablet} {
    margin-top: 32px;
  }
`;

export const LeftColumn = styled.div`
  max-width: 840px;
  display: flex;
  flex-direction: column;
  @media ${baseTheme.media.desktopL} {
    max-width: 696px;
  }
  @media ${baseTheme.media.laptopXL} {
    max-width: 629px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 522px;
    flex: 0 1 48.93%;
  }
  @media ${baseTheme.media.laptop} {
    order: 2;
    max-width: 100%;
    flex: 0 1 100%;
    margin-top: 18px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionTitle = styled.h2`
  display: flex;
  flex-direction: column;
  color: #0f2938;
  font-size: 48px;
  line-height: 126%;
  @media ${baseTheme.media.desktopL} {
    font-size: 40px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 32px;
  }
  @media ${baseTheme.media.tablet} {
    margin-left: 9px;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    font-size: 20px;
  }
  @media ${baseTheme.media.mobileL} {
    display: none;
  }
`;

export const DescriptionTitleMobile = styled.h2`
  display: none;
  @media ${baseTheme.media.mobileL} {
    display: block;
    font-size: 16px;
    line-height: 126%;
  }
`;

export const DescriptionСonditions = styled(SectionElementDescription)`
  width: 100%;
  max-width: 720px;
  margin-top: 24px;
  margin-left: 11px;
  /* margin: 24px 0 0 33px; */
  line-height: 150%;
  /* list-style: inside; */
  & li {
    list-style: disc;
    margin-left: 1em;
  }
  @media ${baseTheme.media.desktopL} {
    font-size: 20px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 18px;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 100%;
  }
  @media ${baseTheme.media.tablet} {
    margin-top: 16px;
    line-height: 167%;
  }
  @media ${baseTheme.media.mobileL} {
    margin-top: 10px;
    font-size: 14px;
    line-height: 143%;
  }
`;

export const RightColumn = styled.div`
  max-width: 840px;
  width: 100%;
  flex: 0 1 49.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  ${Button} {
    margin: 0;
  }
  @media ${baseTheme.media.desktopL} {
    max-width: 696px;
    flex: 0 1 49.15%;
    gap: 12px;
  }
  @media ${baseTheme.media.laptopXL} {
    max-width: 630px;
    flex: 0 1 49.07%;
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 552px;
    flex: 0 1 48.93%;
    ${Button} {
      padding: 20px 0;
      font-size: 22px;
      line-height: 145%;
    }
  }
  @media ${baseTheme.media.laptop} {
    max-width: 100%;
    flex: 0 1 100%;
  }
  @media ${baseTheme.media.mobileM} {
    gap: 10px;
  }
  @media ${baseTheme.media.mobileS} {
    ${Button} {
      padding: 12px 0;
      font-size: 18px;
      line-height: 178%;
    }
  }
`;

export const TotalField = styled.div`
  width: 100%;
  padding: 40px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${baseTheme.media.laptopXL} {
    padding: 20px 25px 20px 37px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 20px 18px 20px 31px;
  }
  @media ${baseTheme.media.mobileL} {
    padding: 20px 18px;
  }
`;

export const Sum = styled.p`
  font-size: 30px;
  font-weight: 600;
  line-height: 100%;
  color: #0f2938;
  @media ${baseTheme.media.laptopXL} {
    font-size: 26px;
  }
  @media ${baseTheme.media.laptopL} {
    font-size: 24px;
  }
  @media ${baseTheme.media.mobileL} {
    font-size: 20px;
  }
`;

export const RemarkInfo = styled.p`
  padding: 20px 0 20px 40px;
  font-size: 20px;
  line-height: 100%;
  color: #2f4d5f;
  @media ${baseTheme.media.laptopXL} {
    padding-left: 37px;
    font-size: 18px;
  }
  @media ${baseTheme.media.laptopL} {
    padding: 20px 31px;
  }
  @media ${baseTheme.media.mobileL} {
    font-size: 14px;
    padding: 20px 29px 20px 17px;
  }
  @media ${baseTheme.media.mobileM} {
    font-size: 12px;
  }
`;

export const PaymentServices = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px 32px;
  padding-top: 5px;
  @media ${baseTheme.media.tablet} {
    padding-top: 10px;
  }
  @media ${baseTheme.media.mobileL} {
    gap: 12px 20px;
  }
`;

export const PaymentService = styled.li<{ img: string; width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

// export const DescriptionItem = styled.li`
//   &:list-style-type {

//   }
// `;
