import { Button } from './styles';

type ButtonAsLinkProps = {
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ButtonAsLink = ({ text, onClick }: ButtonAsLinkProps) => {
  return (
    <Button type='button' onClick={onClick}>
      {text}
    </Button>
  );
};
