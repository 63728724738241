import styled from 'styled-components/macro';
import { SectionElementDescription, SectionSubTitle, SectionTitle } from '../components/style';
import { baseTheme } from 'src/styles';

export const Wrapper = styled.section<{ light?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 72px;
  /* padding: 72px 0px; */
  @media ${baseTheme.media.desktopL} {
    padding-bottom: 14px;
  }
  @media (max-width: 1366px) {
    padding: 0;
  }
  @media ${baseTheme.media.tablet} {
    gap: 48px;
  }
  @media (max-width: 760px) {
    gap: 32px;
    padding: 0 16px;
  }
  @media ${baseTheme.media.mobileM} {
    gap: 24px;
    padding: 0 16px;
  }
`;

// export const Container = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
//   /* justify-content: space-between; */
//   /* gap: 168px; */
// `;

export const Container = styled.div`
  /* width: 100%;
  display: grid; */
  /* grid-template-columns: minmax(auto, 804px) minmax(auto, 948px); */
  /* grid-template-columns: minmax(300px, 804px) minmax(468px, 948px); */
  /* grid-template-columns: max-content max-content; */
  /* grid-template-columns: auto auto;
  justify-content: space-between; */
  /* row-gap: 46px; */
  /* gap: 46px 20px; */
  /* justify-content: space-between; */
  /* gap: 168px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* @media ${baseTheme.media.desktopL} {
    gap: 24px;
  }
  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
  @media ${baseTheme.media.laptopL} {
    gap: 40px 24px;
  } */
`;

// export const TopWrapper = styled.div`
//   width: 100%;
//   max-width: 49.4%;
//   padding-right: 108px;
//   display: flex;
//   justify-content: space-between;
//   align-self: flex-end;
//   @media ${baseTheme.media.desktopL} {
//     padding-right: 60px;
//   }
//   @media ${baseTheme.media.laptopL} {
//     padding-right: 76px;
//   }
//   @media ${baseTheme.media.laptop} {
//     max-width: 58.5%;
//   }
//   @media ${baseTheme.media.tablet} {
//     padding-right: 24px;
//   }
//   @media (max-width: 760px) {
//     max-width: 100%;
//     padding: 0;
//   }
// `;

// export const TopWrapper = styled.div`
//   width: 100%;
//   grid-column: 2;
//   /* max-width: 49.4%; */
//   padding-right: 108px;
//   display: flex;
//   justify-content: space-between;
//   align-self: flex-end;
//   @media ${baseTheme.media.desktopL} {
//     padding-right: 60px;
//   }
//   @media ${baseTheme.media.laptopL} {
//     padding-right: 76px;
//   }
//   @media ${baseTheme.media.laptop} {
//     max-width: 58.5%;
//   }
//   @media ${baseTheme.media.tablet} {
//     padding-right: 24px;
//   }
//   @media (max-width: 760px) {
//     max-width: 100%;
//     padding: 0;
//   }
// `;
export const TopWrapper = styled.div`
  width: 100%;
  grid-column: 2;
  /* max-width: 49.4%; */
  padding-right: 108px;
  display: grid;
  /* grid-template-columns: minmax(209px, 408px) 1fr; */
  /* grid-template-columns: max-content max-content; */
  grid-template-columns: auto auto;
  column-gap: 24px;
  & li {
    align-content: start;
  }
  /* & li:last-child {
    padding-right: 108px;
  } */
  /* justify-content: space-between;
  align-self: flex-end; */
  @media ${baseTheme.media.desktopL} {
    padding-right: 60px;
  }
  @media ${baseTheme.media.laptopL} {
    padding-right: 76px;
    & li:first-child {
      max-width: 288px;
    }
  }
  @media ${baseTheme.media.laptop} {
    max-width: 58.5%;
  }
  @media ${baseTheme.media.tablet} {
    padding-right: 24px;
  }
  @media (max-width: 760px) {
    grid-column: 1 / 3;
    max-width: 100%;
    padding: 0;
  }
`;

// export const BottomWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   /* flex-direction: column;
//   gap: 24px; */
//   justify-content: space-between;
//   /* gap: 168px; */
// `;
export const BottomWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* grid-column: 2; */
  display: grid;
  /* grid-template-columns: minmax(auto, 408px) 1fr; */
  /* grid-template-columns: max-content max-content; */
  grid-template-columns: auto auto;
  column-gap: 24px;
  /* align-content: end; */
  & li {
    height: 100%;
    padding-bottom: 24px;
  }
  & li:last-child {
    max-width: 516px;
    padding-right: 132px;
    background-color: #1a2226;
    ${SectionElementDescription},
    ${SectionSubTitle} {
      color: #f7f7ff;
    }
  }
  @media ${baseTheme.media.desktopL} {
    & li:last-child {
      padding-right: 84px;
    }
  }
  @media ${baseTheme.media.laptopL} {
    & li {
      padding-bottom: 16px;
    }
    & li:last-child {
      padding-right: 39px;
    }
  }
`;

export const PhotoContainer = styled.div`
  /* max-width: 804px;
  height: 480px; */
  align-self: flex-end;
  flex: 0 1 41.9%;
  /* border: 2px solid black; */
  /* background-image: url('/images/main-page/why-section.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 120% 120%; */
  /* @media ${baseTheme.media.laptop} {
    grid-template-rows: 34.8% 58.6%;
    flex-basis: 37.5%;
  } */
  @media ${baseTheme.media.desktopL} {
    flex-basis: 41.4%;
  }
  @media (max-width: 1366px) {
    flex-basis: 41.1%;
  }
  @media ${baseTheme.media.laptopL} {
    flex-basis: 39.8%;
  }
  @media ${baseTheme.media.tablet} {
    flex-basis: 37.5%;
  }
  @media (max-width: 760px) {
    display: none;
  }
`;

export const Photo = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  object-position: right;
  /* flex: 0 1 46%; */
  /* flex-shrink: 1; */
  /* align-self: flex-end; */
  /* object-fit: contain; */
  /* @media (max-width: 1536px) {
    height: 408px;
  }
  @media (max-width: 1366px) {
    height: 408px;
  }
  @media ${baseTheme.media.laptopL} {
    height: 360px;
  }
  @media ${baseTheme.media.tablet} {
    height: 228px;
  } */
  @media ${baseTheme.media.desktopL} {
    height: 408px;
  }

  @media ${baseTheme.media.laptopL} {
    height: 360px;
  }
  @media ${baseTheme.media.tablet} {
    height: 228px;
  }
`;

export const ReasonsContainer = styled.ul`
  max-width: 49.37%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
  gap: 24px;
  & li {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 22px;
    ${SectionElementDescription} {
      /* min-width: 288px; */
      max-width: 360px;
    }
  }
  & li:nth-child(even) {
    padding-right: 132px;
  }
  & li:nth-child(3) {
    padding-top: 216px;
    padding-bottom: 24px;
  }
  & li:last-child {
    padding-top: 216px;
    padding-bottom: 24px;
    background-color: black;
    ${SectionElementDescription},
    ${SectionSubTitle} {
      color: #f7f7ff;
    }
    ${SectionElementDescription} {
      max-width: 298px;
    }
  }
  /* padding-right: 108px; */
  /* display: flex;
  justify-content: space-between;
  gap: 24px; */
  /* align-self: flex-end; */
  /* & li {
    height: 100%;
    padding-bottom: 24px;
  }
  & li:last-child {
    max-width: 100%;
    flex-basis: 54.3%;
    padding-right: 108px;
    background-color: #1a2226;
    ${SectionElementDescription},
    ${SectionSubTitle} {
      color: #f7f7ff;
    }
  } */
  @media ${baseTheme.media.desktopL} {
    max-width: 49.2%;
    & li {
      padding-bottom: 24px;
      ${SectionElementDescription} {
        /* min-width: 246px; */
        max-width: 288px;
      }
    }
    & li:nth-child(even) {
      padding-right: 108px;
    }
    & li:nth-child(2) {
      ${SectionElementDescription} {
        max-width: 262px;
      }
    }
    & li:nth-child(3) {
      padding-top: 144px;
      padding-bottom: 52px;
    }
    & li:last-child {
      padding-top: 144px;
      padding-bottom: 0;
      ${SectionElementDescription} {
        max-width: 244px;
      }
    }
  }
  @media (max-width: 1366px) {
    max-width: 49.1%;
    & li {
      padding-bottom: 58px;
      ${SectionElementDescription} {
        /* min-width: 259px; */
        max-width: 262px;
      }
    }
    & li:nth-child(even) {
      padding-right: 84px;
      ${SectionElementDescription} {
        max-width: 245px;
        /* min-width: 213px; */
      }
    }
    & li:first-child {
      ${SectionElementDescription} {
        max-width: 200px;
        /* min-width: 164px; */
      }
    }
    & li:nth-child(2) {
      ${SectionElementDescription} {
        max-width: 245px;
      }
    }
    & li:nth-child(3) {
      padding-top: 216px;
      padding-right: 10px;
      padding-bottom: 10px;
    }
    & li:last-child {
      padding-top: 216px;
      padding-right: 60px;
      padding-bottom: 0;
      ${SectionElementDescription} {
        max-width: 229px;
      }
    }
  }
  @media ${baseTheme.media.laptopL} {
    max-width: 48%;
    row-gap: 40px;
    & li {
      padding-bottom: 0;
      ${SectionElementDescription} {
        /* min-width: 187px; */
        max-width: 259px;
      }
    }
    & li:nth-child(even) {
      padding-right: 70px;
      ${SectionElementDescription} {
        max-width: 259px;
        /* min-width: 209px; */
      }
    }
    & li:first-child {
      padding-left: 0;
      ${SectionElementDescription} {
        max-width: 164px;
        /* min-width: 164px; */
      }
    }
    & li:nth-child(2) {
      ${SectionElementDescription} {
        max-width: 213px;
      }
    }
    & li:nth-child(3) {
      padding-left: 0;
      padding-top: 208px;
      padding-right: 29px;
      padding-bottom: 16px;
      ${SectionElementDescription} {
        /* min-width: 187px; */
      }
    }
    & li:last-child {
      padding-top: 208px;
      padding-right: 0px;
      padding-bottom: 16px;
      ${SectionElementDescription} {
        max-width: 194px;
      }
    }
  }
  @media ${baseTheme.media.tablet} {
    max-width: 58.5%;
    row-gap: 48px;
    & li {
      ${SectionElementDescription} {
        /* min-width: 140px; */
        max-width: 163px;
      }
    }
    & li:nth-child(even) {
      padding-right: 24px;
      padding-left: 16px;
      ${SectionElementDescription} {
        max-width: 209px;
        /* min-width: 140px; */
      }
    }
    & li:first-child {
      padding-right: 8px;
      ${SectionElementDescription} {
        max-width: 163px;
        /* min-width: 140px; */
      }
    }
    & li:nth-child(2) {
      ${SectionElementDescription} {
        max-width: 209px;
      }
    }
    & li:nth-child(3) {
      padding-left: 0;
      padding-top: 60px;
      padding-right: 8px;
      padding-bottom: 0;
      ${SectionElementDescription} {
        max-width: 187px;
        /* min-width: 140px; */
      }
    }
    & li:last-child {
      padding-top: 60px;
      padding-right: 0px;
      padding-bottom: 0;
      ${SectionElementDescription} {
        max-width: 195px;
      }
    }
  }
  @media (max-width: 760px) {
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 16px;
    & li {
      padding-bottom: 16px;
      ${SectionElementDescription} {
        /* min-width: 100%; */
        max-width: 100%;
      }
    }
    & li:nth-child(even) {
      padding-right: 16px;
      ${SectionElementDescription} {
        /* min-width: 100%; */
        max-width: 100%;
      }
    }
    & li:first-child {
      padding-left: 16px;
      ${SectionElementDescription} {
        /* min-width: 100%; */
        max-width: 100%;
      }
    }
    & li:nth-child(2) {
      padding-left: 16px;
      ${SectionElementDescription} {
        /* min-width: 100%; */
        max-width: 100%;
      }
    }
    & li:nth-child(3) {
      padding-left: 16px;
      padding-top: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      ${SectionElementDescription} {
        /* min-width: 100%; */
      }
    }
    & li:last-child {
      padding-top: 16px;
      padding-right: 16px;
      padding-bottom: 19px;
      ${SectionElementDescription} {
        max-width: 100%;
      }
    }
    /* grid-template-columns: 1fr 1fr;
    gap: 16px;
    & li {
      padding: 16px;
      padding-bottom: 19px;
      border-radius: 16px;
      background-color: #ffffff;
    } */
  }
  @media ${baseTheme.media.mobileM} {
    & li:nth-child(2) {
      padding-bottom: 19px;
    }
  }
  @media (max-width: 360px) {
    gap: 10px 8px;
  }
  /* display: grid; */
  /* grid-template-columns: 43% 53%; */
  /* grid-template-rows: 31.5%; */
  /* grid-template-rows: 1fr 2fr; */
  /* grid-auto-rows: 68.5%; */
  /* grid-auto-rows: 62.5%; */
  /* @media (max-width: 1535px) {
    grid-template-rows: 34.8% 58.6%;
  }
  @media ${baseTheme.media.laptop} {
    flex-basis: 58.5%;
    grid-template-rows: 34.8% 58.6%;
    gap: 48px 32px;
  }
  @media (max-width: 760px) {
    flex-basis: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-template-rows: auto;
    gap: 16px;
  }
  @media ${baseTheme.media.mobileS} {
    gap: 10px 8px;
  } */
  /* @media (max-width: 330px) {
    gap: 10px 2px;
  } */
`;

export const Reason = styled.li`
  /* max-width: 408px; */
  /* min-width: 2; */
  /* max-width: 408px;
  display: grid; */
  /* align-items: end; */
  /* grid-template-rows: max-content;
  align-content: end; */
  /* flex-direction: column; */
  /* align-self: flex-start; */
  /* gap: 24px;
  padding: 0px 24px; */
  ${SectionSubTitle} {
    line-height: 100%;
    margin-bottom: 24px;
  }
  /* @media ${baseTheme.media.desktopL} {
    max-width: 336px;
    ${SectionElementDescription} {
      max-width: 283px;
    }
  } */
  /* @media (max-width: 1366px) {
    max-width: 336px;
    ${SectionElementDescription} {
      max-width: 248px;
    }
  } */
  /* @media ${baseTheme.media.laptopL} {
    gap: 16px;
    padding-right: 0;
    &:nth-of-type(1) {
      padding-left: 0;
      ${SectionElementDescription} {
        max-width: 145px;
      }
    }
    ${SectionElementDescription} {
      max-width: 227px;
    }
  } */
  /* &:nth-child(2n) {
    padding-right: 108px;
  } */
  /* &:last-child {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #1a2226;
    padding-bottom: 58px;
    ${SectionElementDescription},
    ${SectionSubTitle} {
      color: #f7f7ff;
    }
  } */
  @media (max-width: 1890px) {
    /* &:nth-child(2n) {
      padding-right: 60px;
    } */
  }
  @media ${baseTheme.media.laptopL} {
    /* &:last-child {
      ${SectionSubTitle} {
        line-height: 32px;
      }
    }
    &:nth-child(3) {
      ${SectionSubTitle} {
        line-height: 32px;
      }
    } */
  }
  @media ${baseTheme.media.laptop} {
    /* &:nth-child(2n) {
      padding-right: 24px;
      padding-left: 16px;
    }
    &:nth-child(3) {
      max-width: 187px;
      ${SectionSubTitle} {
        line-height: 100%;
      }
    }
    &:last-child {
      padding-left: 16px;
      ${SectionSubTitle} {
        line-height: 100%;
      }
    } */
  }
  /* @media ${baseTheme.media.tablet} {
    &:last-child {
      padding: 60px 39px 44px 16px;
    }
  } */
  @media (max-width: 760px) {
    ${SectionSubTitle} {
      margin-bottom: 8px;
    }
    flex: 0 1 47.77%;
    padding: 16px;
    width: 100%;
    gap: 8px;
    align-self: auto;
    background-color: #ffffff;
    border-radius: 16px;
    &:nth-of-type(1) {
      padding-left: 16px;
      ${SectionElementDescription} {
        max-width: 100%;
      }
    }
    ${SectionElementDescription} {
      max-width: 100%;
    }
    &:nth-child(3) {
      max-width: 100%;
      align-self: auto;
    }
    &:last-child {
      justify-content: flex-start;
      height: auto;
      padding: 16px;
    }
  }
  @media ${baseTheme.media.mobileM} {
    ${SectionSubTitle} {
      font-size: 18px;
    }
    ${SectionElementDescription} {
      line-height: 18px;
    }
  }
  @media ${baseTheme.media.mobileS} {
    padding: 10px;
  }
  @media (max-width: 330px) {
    padding: 6px;
  }
`;
