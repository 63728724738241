import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AuthFormValues } from 'src/features/users/authorization/auth-form';
import { RootState } from 'src/store/store';
import {
  UserResp,
  UserReq,
  LoginResp,
  LogoutReq,
  LogoutResp,
  RecoveryPasswordReq,
  RecoveryPasswordResp,
} from './types';
import { ForgotPasswordFormValues } from 'src/features/users/forgot-password/forgot-password-form';
import { customBaseQuery } from './custom-base-query';

export const exlabAPI = createApi({
  reducerPath: 'exlabAPI',
  tagTypes: ['Exlab'],
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    // fetchExlab: build.query({
    //   query: (url) => `/${url}`,
    //   providesTags: () => ['Exlab'],
    // }),
    registrationUser: build.mutation<unknown, UserReq>({
      query: (data) => ({
        url: '/auth/v0.1/register/',
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['Exlab'],
    }),

    // verificationUser: build.query<unknown, { token: string }>({
    //   // query: (token) => `/auth/v0.1/email-verify/?token=${token}`,
    //   query: ({ token }) => ({
    //     url: `/auth/v0.1/email-verify/`,
    //     method: 'GET',
    //     params: {
    //       token,
    //     },
    //   }),
    // }),
    verificationUser: build.mutation<unknown, { token: string }>({
      // query: (token) => `/auth/v0.1/email-verify/?token=${token}`,
      query: (data) => ({
        url: `/auth/v0.1/email-verify/`,
        method: 'POST',
        body: data,
      }),
    }),

    loginUser: build.mutation<LoginResp, AuthFormValues>({
      query: (data) => ({
        url: '/auth/v0.1/login/',
        method: 'POST',
        body: data,
      }),
    }),

    logout: build.mutation<LogoutResp, LogoutReq>({
      query: (data) => ({
        url: '/auth/v0.1/logout/',
        method: 'POST',
        body: data,
      }),
    }),

    resetPassword: build.mutation<LogoutResp, ForgotPasswordFormValues>({
      query: (data) => ({
        url: '/auth/v0.1/reset-password/',
        method: 'POST',
        body: data,
      }),
    }),

    setNewPassword: build.mutation<RecoveryPasswordResp, RecoveryPasswordReq>({
      query: (data) => ({
        url: '/auth/v0.1/set-new-password/',
        method: 'PATCH',
        body: data,
      }),
    }),

    checkPassword: build.query<unknown, { id: string | undefined; token: string | undefined }>({
      query: ({ id, token }) => `/auth/v0.1/check-password/${id}/${token}`,
    }),
  }),
});
