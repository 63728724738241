import { ButtonAsLink } from '../../buttons';
import { useModalHandler } from '../../modal';
import { ElementDescription } from '../components';
import { SectionElementDescription } from '../components/style';
import { Stage, StageNumber, StageNumberMobile, StagesTitle } from './style';
import { useMediaQuery } from 'src/utils/use-media-query';

type StageCardProps = {
  id: string;
  title: string;
  description: string | string[];
  number: string;
};

// type StageCardProps = {
//   stage: Card[];
// };

export const StageCard = ({ id, title, description, number }: StageCardProps) => {
  const getRegistrationModal = useModalHandler({ notificationType: 'RegistrationModal' });
  const isShowSlider = useMediaQuery('(max-width: 1057px)');
  const isMobile = useMediaQuery('(min-width: 640px)');

  const renderElem = (value: string | string[], order: number) => {
    if (typeof value === 'string') return <ElementDescription description={value} order={order} />;
    const btn = <ButtonAsLink text={value[1]} onClick={getRegistrationModal} />;
    return (
      <SectionElementDescription order={order}>
        {value[0]}
        {btn}
        {!order && <br />}
        {value[2]}
      </SectionElementDescription>
    );
  };

  return (
    <Stage key={id}>
      <StagesTitle>{title}</StagesTitle>
      <StageNumberMobile>{id}</StageNumberMobile>
      {renderElem(description, 2)}
      <StageNumber>{number}</StageNumber>
    </Stage>
  );
};
