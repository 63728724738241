import { Route, Routes } from 'react-router-dom';
import { DevelopmentPage, EmailVerifyPage, MainPage, RecoveryPasswordPage } from 'src/pages';
import { Layout } from '../ui/layout/layout';

export const AppRouter = () => (
  <Routes>
    <Route path='/' element={<Layout />}>
      <Route index element={<MainPage />} />
      <Route path='/members' element={<DevelopmentPage />} />
      <Route path='/merch' element={<DevelopmentPage />} />
      <Route path='/eng-crab' element={<DevelopmentPage />} />
      <Route path='/auth/v0.1/email-verify/' element={<EmailVerifyPage />} />
      <Route path='/auth/v0.1/check-password/:id/:token/' element={<RecoveryPasswordPage />} />
    </Route>
  </Routes>
);
