export const slidesData = [
  {
    id: '1',
    project: 'ecohub.by',
    domain: 'EcoTech',
    photo: 'images/projects/ecohub.svg',
    description:
      'Проект направленный на сохранение нашей планеты с помощью вторичной переработки и сортировки бытовых отходов. В нем собраны и представлены  различные способы переработки, рекомендации по переработке и карта мест сбора и утилизации отходов.',
  },
  {
    id: '2',
    project: 'visaver.online',
    domain: 'Entertainment',
    photo: 'images/projects/logo2.svg',
    description:
      'Удобная платформа, где можно сохранять видео, из любых источников, создавать крутые тематические  подборки и делиться ими с единомышленниками. Изучать интересы своих друзей и всех пользователей данной платформы.',
  },
  {
    id: '3',
    project: 'ullme.com',
    domain: 'Entertainment',
    photo: 'images/projects/ullime.svg',
    description:
      'Это интересный сервис будущего,  который позволит вам найти свою идеальную пару, на основе вашей фотографии. Этот сервис  разработан на основе искусственного интеллекта. Сервис ULLME основан на научных исследованиях, которые доказывают, что люди, похожие друг на друга, притягиваются и становятся отличной парой в будущем.',
  },
  {
    id: '4',
    project: 'Udzel',
    domain: 'Сharity',
    photo: 'images/projects/logo3.svg',
    description:
      'Благотворительная онлайн-платформа, направленная на помощь нуждающимся.  Закрывая свои ежедневные потребности, приобретая различные товары, каждый может помогать и жертвовать тем, кому нужна помощь.',
  },
  {
    id: '5',
    project: 'Tasks',
    domain: 'EdTech4',
    photo: 'images/projects/exlab-tasks.jpg',
    description:
      'Это ТаскТрекер, который позволяет грамотно вести проекты, следить за передвижением задач по дашбордам, эстимировать задачи,создавать отчеты и диаграммы для сбора статистики, Данный проект является внутренним проектом компании ExLab.',
  },
  {
    id: '6',
    project: 'b-free.today',
    domain: 'EdTech4',
    photo: 'images/projects/logo1.svg',
    description:
      'Сторонний проект, который помогает и сопровождает людей с особенностями развития. Это информационный ресурс, который занимается вопросами помощи людям с инвалидностью.',
  },
];
