import { whyExlabSectionData } from '..';
import { ButtonAsLink } from '../../buttons';
import { useModalHandler } from '../../modal';
import { ElementDescription, SubTitle, Title } from '../components';
import { LightTitle, PrimaryTitle, SectionElementDescription } from '../components/style';
import {
  BottomWrapper,
  Container,
  Photo,
  PhotoContainer,
  Reason,
  ReasonsContainer,
  TopWrapper,
  Wrapper,
} from './style';

type ForJuniorSectionProps = {
  primary?: boolean;
  light?: boolean;
};

export const WhyExlabrSection = ({ primary, light }: ForJuniorSectionProps) => (
  <Wrapper id='about'>
    <Title title='Почему ' padding>
      <PrimaryTitle as='span'>Exlab</PrimaryTitle>
      <LightTitle as='span'>?</LightTitle>
    </Title>
    {/* <Container>
      <TopWrapper>
        {whyExlabSectionData.map((reason, index) => {
          if (index < 2) {
            return (
              <Reason key={reason.id}>
                <SubTitle subtitle={reason.title} padding='0'></SubTitle>
                <ElementDescription description={reason.description} />
              </Reason>
            );
          }
        })}
      </TopWrapper>
      <PhotoContainer>
        <Photo src='/images/why-section.jpg' alt='why exlab photo' />
      </PhotoContainer>
      <BottomWrapper>
        {whyExlabSectionData.map((reason, index) => {
          if (index > 1) {
            return (
              <Reason key={reason.id}>
                <SubTitle subtitle={reason.title} padding='0'></SubTitle>
                <ElementDescription description={reason.description} />
              </Reason>
            );
          }
        })}
      </BottomWrapper>
    </Container> */}
    <Container>
      <PhotoContainer>
        <Photo src='/images/why-section.jpg' alt='why exlab photo' loading='lazy' />
      </PhotoContainer>
      <ReasonsContainer>
        {whyExlabSectionData.map((reason) => (
          <Reason key={reason.id}>
            <SubTitle subtitle={reason.title} padding='0'></SubTitle>
            <ElementDescription description={reason.description} />
          </Reason>
        ))}
      </ReasonsContainer>
    </Container>
  </Wrapper>
);

{
  /* <ReasonsContainer>
          {whyExlabSectionData.map((reason, index) => {
            if (index > 1) {
              return (
                <Reason key={reason.id}>
                  <SubTitle subtitle={reason.title} padding='0'></SubTitle>
                  <ElementDescription description={reason.description} />
                </Reason>
              );
            }
          })}
        </ReasonsContainer> */
}

{
  /* <PhotoContainer>
        <Photo src='/images/why-section.jpg' alt='why exlab photo' />
      </PhotoContainer>
      <ReasonsContainer>
        {whyExlabSectionData.map((reason) => (
          <Reason key={reason.id}>
            <SubTitle subtitle={reason.title} padding='0'></SubTitle>
            <ElementDescription description={reason.description} />
          </Reason>
        ))}
      </ReasonsContainer> */
}
