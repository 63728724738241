import { useAppDispatch } from 'src/store/hook';
import { currentModalHandler } from './current-modal-handler';
import { Modals } from './modals-config';
import { closeModal } from 'src/store/reducers/modal-slice';

export const useModal = <P>(name: Modals) => {
  const dispatch = useAppDispatch();
  return {
    open: (props: P) => {
      document.body.classList.add('scroll_locked');
      // document.body.style.overflowY = 'hidden';
      currentModalHandler.set({ name, props: props });
    },
    close: () => {
      dispatch(closeModal());
      document.body.classList.remove('scroll_locked');
      // document.body.style.overflowY = '';
      currentModalHandler.set(null);
    },
  };
};
