import { useModalHandler } from 'src/features/ui/modal';
import { Main } from './styles';
import { useParams } from 'react-router-dom';
import { exlabAPI } from 'src/services/exlab-service';
import { useEffect } from 'react';

export const RecoveryPasswordPage = () => {
  const { id, token } = useParams();
  // console.log(token);
  // console.log(id);
  const displaySendNewPasswordModal = useModalHandler({
    notificationType: 'SendNewPasswordModal',
    token,
    uidb64: id,
  });

  const { data, isSuccess, isError } = exlabAPI.useCheckPasswordQuery({ id, token });

  useEffect(() => {
    // console.log('data', data);
  }, [data, token]);

  useEffect(() => {
    if (isSuccess) {
      displaySendNewPasswordModal();
      // console.log('suc');
    }
    if (isError) {
      // console.log('err verify');
    }
  }, [displaySendNewPasswordModal, isError, isSuccess]);

  return <Main />;
};
