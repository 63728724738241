import { SectionSubTitle } from './style';

type SubTitleProps = {
  subtitle: string;
  padding?: string | undefined;
};

export const SubTitle = ({ subtitle, padding }: SubTitleProps) => (
  <SectionSubTitle padding={padding}>{subtitle}</SectionSubTitle>
);
