import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation, FreeMode, Thumbs } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
// import './swiper.css';
import { useState } from 'react';
import {
  Container,
  DescriptionTitle,
  NavigationDomain,
  NavigationText,
  NavigationTitle,
  NavigationWrapper,
  SlideDescription,
  SlideNumber,
  SlidePhoto,
  SlidesWrapper,
} from './styles';
import { ElementDescription } from '../main-page-section/components';

type SliderData = {
  id: string;
  project: string;
  domain: string;
  photo: string;
  description: string;
};

type SliderProps = {
  sliderData: SliderData[];
};

export const Slider = ({ sliderData }: SliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

  return (
    <Container>
      <Swiper
        onSwiper={setThumbsSwiper}
        // spaceBetween={10}
        keyboard={{
          enabled: true,
        }}
        slidesPerView={7}
        direction='vertical'
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Keyboard, Thumbs]}
        className='navigation'
      >
        <SlidesWrapper>
          {sliderData.map((slider) => (
            <SwiperSlide key={slider.id}>
              <SlideNumber>{`0${slider.id}`}</SlideNumber>
              <NavigationText>
                <NavigationTitle>{slider.project}</NavigationTitle>
                <NavigationDomain>{slider.domain}</NavigationDomain>
              </NavigationText>
            </SwiperSlide>
          ))}
        </SlidesWrapper>
      </Swiper>
      <Swiper
        // spaceBetween={10}
        // speed={600}
        // parallax={true}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        direction='vertical'
        // grabCursor={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Pagination, Thumbs]}
        className='sliderDescription'
      >
        {sliderData.map((slider) => (
          <SwiperSlide key={slider.id}>
            <SlidePhoto img={slider.photo}></SlidePhoto>
            <SlideDescription>
              <DescriptionTitle>{slider.project}</DescriptionTitle>
              <ElementDescription description={slider.description} />
            </SlideDescription>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        // spaceBetween={24}
        // speed={600}
        // parallax={true}
        centeredSlides={true}
        slidesPerView={2}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
          },
          340: {
            slidesPerView: 1.29,
          },
          390: {
            slidesPerView: 1.35,
          },
          420: {
            slidesPerView: 1.5,
          },
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        // grabCursor={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Pagination, Thumbs]}
        className='mobileSlider'
      >
        {sliderData.map((slider) => (
          <SwiperSlide key={slider.id}>
            <SlidePhoto img={slider.photo}>{/* <h2>{slider.project}</h2> */}</SlidePhoto>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );

  // return (
  //   <>
  //     <Swiper
  //       spaceBetween={10}
  //       navigation={true}
  //       thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
  //       modules={[FreeMode, Navigation, Thumbs]}
  //       className='mySwiper2'
  //     >
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-1.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-2.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-3.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-4.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-5.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-6.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-7.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-8.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-9.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-10.jpg' />
  //       </SwiperSlide>
  //     </Swiper>
  //     <Swiper
  //       onSwiper={setThumbsSwiper}
  //       spaceBetween={10}
  //       slidesPerView={4}
  //       freeMode={true}
  //       watchSlidesProgress={true}
  //       modules={[FreeMode, Navigation, Thumbs]}
  //       className='mySwiper'
  //     >
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-1.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-2.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-3.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-4.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-5.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-6.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-7.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-8.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-9.jpg' />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <img src='https://swiperjs.com/demos/images/nature-10.jpg' />
  //       </SwiperSlide>
  //     </Swiper>
  //   </>
  // );
};
