export const description = [
  'Присоединиться к команде и участвовать в стажировке',
  'Доступ к профматериалам от менторов проекта',
  'Частные сессии с менторами 1,5 часа 2 раза в месяц (2\u00A0академических часа) в группах до 8 человек',
  'Дополнительные сессии с менторами на интересующие вопросы',
  'Участие в English Crab, HR сессии, Hugs',
  'Бонус Gift Bag',
  'Интервью с HR специалистом IT-компаний партнеров (при\u00A0активной\u00A0работе от 3-х месяцев на проекте)',
  'Получение фидбека от IT компаний по итогам прохождения\u00A0собеседования',
];

export const paymentServices = [
  { id: '1', width: '69px', height: '22px' },
  { id: '2', width: '48px', height: '48px' },
  { id: '3', width: '134px', height: '28px' },
  { id: '4', width: '37px', height: '29px' },
  { id: '5', width: '106px', height: '24px' },
  { id: '6', width: '46px', height: '51px' },
  { id: '7', width: '77px', height: '33px' },
  { id: '8', width: '51px', height: '33px' },
  { id: '9', width: '83px', height: '37px' },
  { id: '10', width: '122px', height: '25px' },
];
