import { Loader } from '../../loader';
import { Button } from './styles';

type ButtonPrimaryProps = {
  text: string;
  id?: string;
  type?: 'button' | 'submit';
  form?: string;
  isNotification?: boolean;
  notColored?: boolean;
  isHeader?: boolean;
  isLoading?: boolean;
  weight?: boolean;
  loader?: JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ButtonPrimary = ({
  text,
  id,
  type,
  form,
  isNotification,
  notColored,
  isHeader,
  isLoading,
  weight,
  onClick,
  loader,
}: ButtonPrimaryProps) => {
  return (
    <Button
      type={type}
      id={id}
      form={form}
      onClick={onClick}
      isNotification={isNotification}
      notColored={notColored}
      isHeader={isHeader}
      weight={weight}
    >
      {isLoading ? loader : text}
    </Button>
  );
};
