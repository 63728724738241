import { Outlet } from 'react-router-dom';
import { Header } from '../header/header';
import { ModalRenderer } from '../modal';
import { Footer } from '../footer';

export const Layout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
    <ModalRenderer />
  </>
);
