import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'src/store/store';
import { setUser, logoutUser } from 'src/store/reducers/user-slice';
import { Mutex } from 'async-mutex';
import { Cookies } from 'react-cookie';
import { LoginResp } from './types';

const BASE_URL = 'https://dev.exlab.team';

const cookies = new Cookies();
const refreshToken: string = cookies.get('userRefreshToken');

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { isAuth, token } = (getState() as RootState).user;
    // console.log('isAuth', isAuth);
    if (isAuth) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401 && refreshToken) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/v0.1/refresh/',
            method: 'POST',
            body: { refresh: refreshToken },
          },
          api,
          extraOptions
        );
        const data = refreshResult.data as LoginResp;

        if (data) {
          // store the new token
          const { access, refresh } = data;
          api.dispatch(setUser({ isAuth: true, token: access, refreshToken: refresh }));
          cookies.set('userRefreshToken', refresh);
          cookies.set('userAccessToken', access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logoutUser());
          // cookies.remove('userRefreshToken');

          // window.location.href = '/'; //редиректит на главную страницу в случае ошибки авторизации

          //can display auth form
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
