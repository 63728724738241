import { useState } from 'react';
import { Button, AccordionContainer, Content, Icon } from './styles';
type AccordionProps = {
  title: string;
  children?: JSX.Element;
};

export const Accordion = ({ title, children }: AccordionProps) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const handleClik = () => toggleIsOpen(!isOpen);
  return (
    <AccordionContainer>
      <Button onClick={handleClik}>
        {title}
        <Icon isOpen={isOpen} />
      </Button>
      {isOpen && <Content>{children}</Content>}
    </AccordionContainer>
  );
};
